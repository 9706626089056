/*
 * 업무구분 : 청약
 * 화면 명 : MSPPS242D
 * 화면 설명: 전자서명 중단건
             청약 파트 전자서명(중단건,재처리)
 *            
 */
<template>
  <ur-page-container class="msp" :show-title="false" :topButton="false">
    <ur-box-container v-if="!isNodata" alignV="start" componentid="" direction="column" class="status-list type-expand">
      <mo-list :list-data="rsltListIntrs">
        <template #list-item="{item,index}">      
          <mo-list-item class=""> 
            <!-- expanded expand-only-btn -->
            <msp-expand btn-area-first title-first expandable :preventScrollWhenExpand="true" :ref="'expItem_'+index" class="mo-list-expand w100" btn-area-class="fexTy3 align-item-start"> 
              <template #title>
                <div class="list-item__contents">
                  <div class="list-item__contents__title pt6">
                    <!-- 상품명 -->
                    <span class="name txtSkip fs19rem fwb">{{item.mnPrdtNm}}</span>
                  </div>
                  <div class="list-item__contents__info mt6">
                    <span>
                      <span class="fs14rem mr6 crTy-bk7">피보험자</span>
                      <span class="crTy-bk1 fs16rem txtSkip--psjjh maxW70 min70 ls--1 underline" @click="fn_PopupCustNmUiCard(item.insrdChnlCustId)">{{item.insrdNm === ' ' ? item.mnContrNm : item.insrdNm}}</span>
                    </span>
                    <span>
                      <em class="em_normal">|</em>
                      <span class="fs14rem mr8 crTy-bk7">청약일</span>
                      <span class="crTy-bk1 fs16rem ls--1">{{item.insrOfrYmd}}</span>
                    </span>
                  </div>
                  <div class="list-item__contents__info mt6 mb10">
                    <span>
                      <span class="fs14rem mr8 crTy-bk7">보험료</span>
                      <span class="crTy-bk1 fs16rem fwb crTy-blue3">{{$bizUtil.numberWithCommasCurr(item.contPrm, item.crncyCd, 'Y', 'Y', 4)}}원</span>
                    </span>
                  </div>
                </div>
              </template>
              <template #btn>
                <mo-button class="link-arrow down mt10"></mo-button>
              </template>
              <template #content>
                <div class="list-item-detail gray_style bdnone margin--full pt20 pb20">
                  <ur-box-container class="con--padding ">
                    <div class="contents-row">
                        <span class="dsInline crTy-bk7 fs14rem mr20 min60">계약자</span>
                        <span class="crTy-bk1 fs16rem txtSkip--psjjh maxW70 min70 ls--1 underline" @click="fn_PopupCustNmUiCard(item.mobslChnlCustId)">{{item.mnContrNm}}</span>
                    </div>
                    <div class="contents-row mt4">
                        <span class="dsInline crTy-bk7 fs14rem mr20 min60">영수증번호</span>
                        <span class="fs16rem">{{item.vuchId}}</span>
                    </div>
                    <div class="contents-row">
                        <span class="dsInline crTy-bk7 fs14rem mr20 min60">교부방식</span><span class="crTy-bk1 fs16rem txtSkip--psjjh maxW70 min70 ls--1">{{item.prcssStatus}}</span>
                    </div>
                  </ur-box-container>
                </div>
              </template>
            </msp-expand>
          </mo-list-item>
          <ur-box-container alignV="start" componentid="" direction="column" class="ns-input-box expand_bottomline">
            <div class="ns-btn-relative-area mt0">
              <div class="ns-btn-relative-s">
                <div class="relative-div mb5">
                  <!-- 청약 이어하기 -->
                  <mo-button componentid="" class="ns-btn-round white ml0 mr0"  @click="fn_MovCntuElst(item)">{{item.ofrMmgNm2}}</mo-button>                  
                </div>
              </div>
            </div>
          </ur-box-container>
        </template>
      </mo-list>
    </ur-box-container>
    
    <!-- 중단건 NoData 영역 ur-box-containe  -->        
    <PSNodataMesgBoxForList v-if="isNodata" ref="nodataMesgBox" :mesgText="mesgText"/>        
    <!-- / 중단건 NoData 영역 ur-box-containe -->  

  </ur-page-container>
</template>

<script>
/***********************************************************************************
* INCLUDE 영역
***********************************************************************************/
import Msg from '@/systems/webkit/msg/msg'
import { createScrollAnimateInstance } from '@/utils/scroll/scroll-animate'
import PSCommUtil from '@/ui/ps/comm/PSCommUtil'
import PSDateUtil from '@/ui/ps/comm/PSDateUtil'
import PSConstants from '@/config/constants/psConstants'
import PSServiceManager from '@/ui/ps/comm/PSServiceManager' 
import PSNodataMesgBoxForList from '@/ui/ps/comp/PSNodataMesgBoxForList'

export default {

  /***********************************************************************************
  * Vue 파일 속성 정의 영역
  * - name : 화면ID동일 영문만 기입 특수문자 한글 기입시 에러남
  * - screenId : 화면ID
  * - components : UI TAG 컴포넌트 정의
  ***********************************************************************************/
  name: 'MSPPS242D', 
  screenId: 'MSPPS242D',

  components: {
    'PSNodataMesgBoxForList' : PSNodataMesgBoxForList
  },//componets,

  /***********************************************************************************
  * 화면 UI Property 정의
  * - 파리미터 받는 변수 props 정의
  ***********************************************************************************/
  props:{
    idx                : {type:Number, default:-1}, // 부여받은 탭 인덱스
    curntTabIdx        : {type:Number, default:-1}, // 부모 선택한 현재 탭 인덱스    
    isTabletEvn        : {type:Boolean, default: false},  // 테블릿 환경 여부
    holyDayChkDateArry : {type:Array,  default:()=>{ return [] }}  // 현재날짜+10일까지 날짜정보 배열  부모로 부터 전달 받음
  },//props

  /***********************************************************************************
  * 팝업일때 팝업 활성 형태 속성 
  * - 풀 팝업 형태 일때 mode 값 설정
  ***********************************************************************************/
  /*
  modalProps: {
    mode: 'right' // 우->좌 open popup (defalut: 하->상)
  },
  */

  /***********************************************************************************
  * 화면 전역 변수 선언 영역
  * - UI 바인딩 및 스크립트 화면 전역변수 정의
  ***********************************************************************************/
  data () {
    return {      
      PSConstants   : PSConstants, // 청약 상수
      srchOptData   : null ,       //검색 옵션
      mesgText      : '',            // 데이터 없음 표시 메세지
      rsltCnt       : 0,            // 출력리스트 갯수
      isNodata      : false,        // 데이터 없음 Flag
      sortFieldListIntrs    : [] ,  // 중단건 sort 필드 List      
      rsltListIntrs         : [],   // 중단건 조회결과 출력 데이터 List      
      rsltOrgcpListIntrs    : [],   //  중단건 조회결과 원본 데이터 List
      pSElstIntrsCseReqSVO2 : {},   // 중단건 페이징 파라미터셋 정보
      fileFullPathApp : '',         //  전자서명 OZ앱 중단건 파일경로
      stopListPageNo  : 1,           // 중단건 현재 페이지
      stopListVuchId  : '',          // 중단건 영수증번호
      stopListTotCnt  : 0,           //중단건 조회 총 레코드 개수      
      stopDeleteListVuchId: '',      // 중단건 삭제 영수증번호 목록
      stopListCnt     : 0,           // 중단건 영수증 번호 조회 카운트     
      _temp : null
    }
  },//data

  /***********************************************************************************
  * 컴포넌트 BASE 라우터 네비게이션 가드 함수 정의영역
  * - 화면전환 Route Life Cycle 함수
  * - 주로 next(fals) 진행불가처리 ,next()진행 처리 스크립트 컨트롤용도
  *   사용시 함수 주석 풀고 스크립트 작성 할것  
  ***********************************************************************************/
  
  //router에 의해 화면진입시 호출 인스턴스 생성되기전이라 this 자원 접근안됨  
  //beforeRouteEnter (to, from, next) { 반드시  next(fals) OR next()  호출 },//beforeRouteEnter

  //router에 의해 화면을 벗어날때 호출 
  //beforeRouteLeave (to, from, next) { 반드시  next(fals) OR next()  호출 },//beforeRouteLeave


 /***********************************************************************************
  * Life Cycle 함수 정의 영역
  * - 각단계별 예약 함수에 맞게 스크립트 작성       
  ***********************************************************************************/

  // Life Cycle Step-1 인스턴스 초기화 후 데이터 관찰/이벤트/감시자 설정 전 호출 - 호출 부모 우선
  beforeCreate () {},//beforeCreate

  // Life Cycle Step-2 인스턴스가 작성된 후 동기적 호출 - this 접근 가능, el 접근 불가 - 호출 부모 우선  
  created () {
    
    this.sortFieldListIntrs = [
       {field : 'insrOfrYmdSort'  , label : '청약일'     , sort: 'DESC'}
      ,{field : 'insrdNm'         , label : '피보험자'   , sort: 'ASC'}
      ,{field : 'mnContrNm'       , label : '계약자'     , sort: 'ASC'}
    ]
  },//created

  // Life Cycle Step-3 마운트되기 바로 직전 전 호출 - this 접근 가능, el 접근 불가 - 호출 자식 우선
  beforeMount () {},//beforeMount

  // Life Cycle Step-4 마운트 된 후 호출 - 여기서 부터 모든 자원 접근 가능 - 호출 자식 우선
  // 이곳에서 초기설정 스크립트 작성
  mounted () {
    
    //부모로 부터 sort change event 등록
    this.$on('on-sort-change',this.fn_SortHandler)

    //부모로 부터 on-scroll-bottom 이벤트 등록 
    this.$on('on-scroll-bottom',this.fn_ScrollBottomHandler)

    //부모로 부터 on-search 이벤트 등록
    this.$on('on-search',this.fn_SearchEvent)

    //부모에게 mounted 이벤트 발생
    this.$emit('on-child-tab-mounted',this)

    if(! this.isTabletEvn){
      this.fn_TableStat('EPSC062') // 테블릿 사용 환경에서만 조회 가능 합니다.
    }
    
  },//mounted

  // Life Cycle Step-5 props 정의된 데이터가 변경되고 렌더링 되기 전 호출되는 수시호출함수
  beforeUpdate () {},//beforeUpdate

  // Life Cycle Step-6 props 데이터가 변경되고 렌더링 업데이트 후 호출되는 수시호출함수
  updated () {},//updated

  // Life Cycle Step-7 인스턴스가 Remove 되기 전 호출
  beforeDestroy () {},//beforeDestroy

  // Life Cycle Step-8 인스턴스가 Remove 된 후 호출
  destroyed () {
    this.$off('on-sort-change',this.fn_SortHandler)
    this.$off('on-scroll-bottom',this.fn_ScrollBottomHandler)
    this.$off('on-search',this.fn_SearchEvent)
  },//destroyed

  
  /***********************************************************************************
   * watch 정의 영역
   * - data 영역에 바인딩 변수 정의한 값에 대한 값 변경시 지정 호출 함수 정의 
   *   (변수에 대한 값 변경시 반응형 콜백 처리)
   *   ex) data 영역 정의한 익명변수 userNm 
   *       userNm : function() { this.message + ' 님 안녕하세요!'}
  ***********************************************************************************/
  watch: {
    rsltListIntrs(){
      this.rsltCnt   = this.rsltListIntrs ? this.rsltListIntrs.length : 0
      this.isNodata  = this.rsltCnt == 0 ?  true : false      
    },   
  },//watch

 /***********************************************************************************
  * Computed 함수 정의 영역
  * - data 영역에 바인딩 변수 정의한 값에 대한 값 변경시 지정 호출 getter 함수 정의
  *   지정 함수는 반드시 리턴 하는 함수로 작성
  *   (변수에 대한 값 변경시 반응형 getter 함수 지정 처리)
  *   ex) data 영역 정의한 익명변수 userNm 
  *   userNm : function() { return this.userNm = this.userNm? this.userNm.trim() : '' } 
  ***********************************************************************************/
  computed: {},//computed

 /***********************************************************************************
  * 사용자 함수 정의 영역
  ***********************************************************************************/

  methods: {
    
  /*---------------------------------------------------------------------------------
  * UI 이벤트 핸들러 영역
  ---------------------------------------------------------------------------------*/


    /******************************************************************************
    * Function명  : fn_SortHandler
    * 설명        : Sort 처리 이벤트 핸들러
    *               pSortOrder = {field : 'title' , label : '제목' , sort: 'ASC' or 'DESC'}
    ******************************************************************************/
    fn_SortHandler(pSortOrder) {
      console.log('MSPPS242D 중단건 fn_SortHandler !!!',pSortOrder)

      let sortField   = pSortOrder.field
      let sortOrderby = pSortOrder.sort //ASC or DESC
      
      switch(sortField){       
        case 'insrOfrYmdSort': // 청약일 날짜형 String 을 Date 형으로 변환 처리 해서 sort
          PSCommUtil.fn_SortListValueDateString(sortOrderby, this.rsltListIntrs, sortField, 'yyyy-MM-dd') //yyyy-MM-dd
          break
        case 'insrdNm':  //피보험자 String 형 sort
        case 'mnContrNm':  //계약자 String 형 sort
          PSCommUtil.fn_SortListValueString(sortOrderby, this.rsltListIntrs, sortField)
          break
      }//switch
    },

    /******************************************************************************
    * Function명  : fn_ScrollBottomHandler
    * 설명        : Scroll Bottom 핸들러
    ******************************************************************************/
    fn_ScrollBottomHandler() {
       this.fn_ServiceData('S2_N')
    },


  /*---------------------------------------------------------------------------------
  * UI Popup 관련 처리 영역
  ---------------------------------------------------------------------------------*/

    /******************************************************************************
     * Function명 : fn_PopupCustNmUiCard
     * 설명 : 계약자 피보험자 클릭시 nameUI 실행하는 함수
     ******************************************************************************/
    fn_PopupCustNmUiCard (pChnlCustId) {
      let nmUiObject = {
        chnlCustId : pChnlCustId, //고객채널 ID
        cnsltNo    : PSServiceManager.getUserInfo('userId'),  // FC 사번
        parentId   : this.$options.screenId //화면ID]
      }      
      this.$cmUtil.fn_OpenCustNmUiCard(this, nmUiObject, (modalId)=>{
        //Close 콜백 처리
        return
      })
      
    },

    /******************************************************************************
     * Function명 : fn_MovCntuElst
     * 설명 : 중단건 열기(전자서명) , 계약자(전자서명) 
            , 예금주(전자서명),재전송 상태 전자서명 진행 화면 이동 처리
     ******************************************************************************/
    fn_MovCntuElst (itemData){
      let routerParam = null
      let o = null

      if ( itemData.ofrMmgNm !== '재전송' ) {
        o = {
          contrJobpNm       : itemData.contrJobpNm, // 계약자직장명
          insrdJobpNm       : itemData.insrdJobpNm, // 피보험자직장명
          bnkAccnId         : itemData.bnkAccnId, // 계좌정보ID
          crmtBuagCcardOut  : itemData.crmtBuagCcardOut, // 24.05 ASR240400333 카드납추가
          mnisdMiorYn       : itemData.mnisdMiorYn, // 주피보험자미성년자여부
          unchYn            : itemData.unchYn, // 태아여부
          contrInsrdSameYn  : itemData.contrInsrdSameYn, // 계피상이여부
          contrElstYn       : itemData.contrElstYn, // 계약자 전자서명 여부
          insrdElstYn       : itemData.insrdElstYn, // 피보험자 전자서명 여부
          dpstrElstYn       : itemData.dpstrElstYn, // 예금주 전자서명 여부
          contrElstYmd      : itemData.contrElstYmd, // 계약자 전자서명일
          insrdElstYmd      : itemData.insrdElstYmd, // 피보험자 전자서명일
          elsePrgStatCd     : itemData.elstPrgStatCd,
          elstPlanId        : itemData.elstPlanId,
          aisdTrtyFgr       : itemData.aisdTrtyFgr, // 종피보험자특약수
          insrdTrtyFgr      : itemData.insrdTrtyFgr, // 피보험자특약수
          zzSecusFrmId      : itemData.secusFrmCd, // 증권양식값
          zzUntyCoITId      : itemData.untyCovrInsrTypCd, // 통합보장보험유형값
          zzPtclrPrdTId     : itemData.ptclrPrdtTypCd, // 특이상품유형값
          zzPdinsRCCId      : itemData.pdinsRCCId, // 상품보험관계구성코드
          zzKliaPrdtId      : itemData.kliaInsrScCd, // 생보협회상품분류값
          vuchId            : itemData.vuchId,          
          fullFilePath      : this.fileFullPathApp + itemData.vuchId + '.ozd',
          prmPadTVId        : itemData.prmPadTypValD // 보험료납입유형값RD / (ASR240400333_24.05 장한나)
        }
        //this.$MenuManager.fn_RouterPushSrnId('TSSPS110M', {reqNameID: 'TSSPS213D_2', itemData: o, step: '1'})
        routerParam = {name : 'MSPPS420M' , params: {reqNameID: 'MSPPS240M_2', itemData: o, step: '1'}}
        PSCommUtil.fn_RoutepushSetKeepAlive(routerParam,false)

      } else {
        if (itemData.abnrmCmpltYn === 'Y') {
          //this.$MenuManager.fn_RouterPushSrnId('TSSPS180M', {reqNameID: 'TSSPS213D_3_ERR', itemData: itemData, step: '1'})
          routerParam = {name : 'MSPPS610M' , params: {reqNameID: 'MSPPS240M_3_ERR', itemData: itemData, step: '1'}} 
          PSCommUtil.fn_RoutepushSetKeepAlive(routerParam,false)
        } else {
          //this.$MenuManager.fn_RouterPushSrnId('TSSPS180M', {reqNameID: 'TSSPS213D_3', itemData: itemData, step: '1'})
          routerParam = {name : 'MSPPS610M' , params: {reqNameID: 'MSPPS240M_3', itemData: itemData, step: '1'}}
          PSCommUtil.fn_RoutepushSetKeepAlive(routerParam,false)
        }
      }  
    },

  /*---------------------------------------------------------------------------------
  * Data Service 핸들러 영역
  ---------------------------------------------------------------------------------*/


  /******************************************************************************
   * Function명  : fn_SearchEvent
   * 설명        : 중단건 조회 케이스 처리
   *               고객명 / 상품명(필터) / 영수증번호(필터) 
   *               검색 1 : 리스트출력 2 방식으로 동시호출로 편작
  ******************************************************************************/
  fn_SearchEvent (pSrchOptData) {

    if(! this.isTabletEvn){
      this.fn_TableStat('EPSC062') // 테블릿 사용 환경에서만 조회 가능 합니다.
      return
    }

    this.srchOptData = pSrchOptData

    let srchSc        = (this.srchOptData)? this.srchOptData.searchScVal   : '1' //검색 구분 (1:고객명,2:상품명,3:영수증번호)
    let chnlCustId    = (this.srchOptData)? this.srchOptData.chnlCustId    : '' //고객명으로 검색시 선택한 채널 고객id
    let searchKeyword = (this.srchOptData)? this.srchOptData.searchKeyword : '' //검색 키워드 (고객명, 상품명 , 영수증번호)


    //서비스 호출 여부 판단
    if(! searchKeyword) { // 검색어 공백시 재조회 

      this.stopListVuchId = '' // 중단건 영수증번호
      this.stopDeleteListVuchId = '' // 중단건 삭제 영수증번호 목록

      //중단건 조회
      this.fn_StopListNoEltrnSearch() //OZ앱 중단건 정보 가져오기

    } else if(this.rsltOrgcpListIntrs) { //마지막 조회된 원본 데이터로 필터 처리

      this.rsltListIntrs = []
      this.rsltOrgcpListIntrs.forEach(item => {
        switch (srchSc) {
          case PSConstants.PLAN_OFR_SEARCH_OPT_CUST_NM.key: //1:고객명
            if ( chnlCustId === item.mobslChnlCustId || chnlCustId === item.insrdChnlCustId ) {
                this.rsltListIntrs.push(item)
            }         
            break
          case PSConstants.PLAN_OFR_SEARCH_OPT_PRDT_NM.key: //2:상품명
            if (item.mnPrdtNm.indexOf(searchKeyword) > -1) {
              this.rsltListIntrs.push(item)
            }
            break
          case PSConstants.PLAN_OFR_SEARCH_OPT_VUCH_NUM.key: //3:영수증번호
            if (item.vuchId.indexOf(searchKeyword) > -1) {
              this.rsltListIntrs.push(item)
            }
            break              
        }//switch

      })// forEach this.rsltOrgcpListIntrs

      if(this.rsltListIntrs.length == 0){
        this.fn_TableStat('EPSC060') //검색결과가 존재하지 않습니다
      }else{
        let isSortReset = false
        this.$emit('on-service-result',this.idx,this.rsltListIntrs,isSortReset)
      }
      
    }

  },


  /******************************************************************************
  * Function명 : fn_StopListNoEltrnSearch
  * 설명 : OZ전자서명 앱에 중단건 요청 
  ******************************************************************************/
  fn_StopListNoEltrnSearch (){
    this.stopListCnt = 0 // 안쓰는 fn_stopListNoLocalSearch +1증가
    
    window.fdpbridge.exec('getTmpStoreListPlugin'
                          , {}
                          , this.fn_GetTmpStoreListPluginResult.bind(this)
                          , (error)=>{
                              window.vue.error(error)
                              this.stopListCnt++
                              this.fn_StopListLoadCmplt()
                          })
  },
  
  
  /******************************************************************************
  * Function명 : fn_GetTmpStoreListPluginResult
  * 설명 : OZ전자서명 앱에 중단건 요청 호출 결과 처리           
  ******************************************************************************/
  fn_GetTmpStoreListPluginResult (result) {
      // 성공콜백
      // 전자서명 앱에서 오는 데이터 정제
      // 아래와 같이 들어옴
      /* let testData = '/storage/emulated/0/Android/data/forcs.ozviewer.fw.samsunglife/cache/tempsave/ozd/44142321904132600202.ozd
                        ,/storage/emulated/0/Android/data/forcs.ozviewer.fw.samsunglife/cache/tempsave/ozd/05142385954132600202.ozd'      
      */
      this.stopListCnt++
      try {
        // let splitList = ['/data/user/0/udk.ezpdfview.fw.samsunglife/cache/87055340024161118102.pdf.tmp', '/data/user/0/udk.ezpdfview.fw.samsunglife/cache/87055340024161118102.pdf.tmp']
        console.log('getTmpStoreListPluginResult::::' + JSON.stringify(result))
        let splitList = result.data.split(',')
        let rowStr = ''
        let isDoneStr = ''

        if(result.data && String(result.data).trim() != ''){

          splitList = result.data.split(',')
          this.fileFullPathApp = splitList[0].trim().substring( splitList[0].trim().lastIndexOf('/') + 1, 0 )
          
          for (let i = 0; i < splitList.length; i++) {
            rowStr = splitList[i].trim().substring(splitList[i].trim().lastIndexOf('/') + 1, splitList[i].trim().indexOf('.ozd'))            
            if(rowStr.trim() == "") continue
            if ( i !== 0 ) rowStr = ',' + rowStr
            isDoneStr += rowStr
          }// for i

          if ( this.stopListVuchId.length > 0 ) {
            this.stopListVuchId += (',' + isDoneStr)
          } else{
            this.stopListVuchId = isDoneStr
          }

        }
        // alert('OZ전자서명 앱 캐시 파일리스트 - ' + isDoneStr)
      } catch (error) {
        window.vue.error(error)
      }
      
      //this.fn_StopListLoadCmplt()
      this.fn_StopListNoLocalSearch()
    },


    /******************************************************************************
     * Function명 : fn_stopListNoLocalSearch
     * 설명 : 신태블릿 앱 내 캐시 영역 pdf 폴더 파일리스트 조회
     ******************************************************************************/
    fn_StopListNoLocalSearch () {
      let lv_Vm = this
      this.stopListCnt++
      window.fdpbridge.exec('getPSFileListPlugin', {},
        (result) => {
          // 성공콜백
          // 신태블릿 앱 내 캐시 영역 pdf 파일리스트 정제
          // 아래와 같이 들어옴
          // let testStr = 'abc.pdf|abcdef.pdf|ddr5.pdf|ddeeww.pdf'
          console.log('getPSFileListPlugin result:::' + JSON.stringify(result))

          try {
            let splitList = null
            let rowStr = ''
            let isDoneStr = ''
            
            if(result.data && String(result.data).trim() != '') {
                splitList = result.data.split(',')
                //사용하지 않는 변수 
                //lv_Vm.fileFullPathLocal = splitList[0].trim().substring( splitList[0].trim().lastIndexOf('/') + 1, 0 )
              for (let i = 0; i < splitList.length; i++) {
                rowStr = splitList[i].trim().substring(splitList[i].trim().lastIndexOf('/') + 1, splitList[i].trim().indexOf('.ozd'))
                if(rowStr.trim() == "") continue
                if ( i !== 0 ) rowStr = ',' + rowStr
                isDoneStr += rowStr
              }// for i

              if ( lv_Vm.stopListVuchId.length > 0 ) {
                lv_Vm.stopListVuchId += (',' + isDoneStr)
              } else lv_Vm.stopListVuchId = isDoneStr

            }
            
          } catch (error) {
            alert('getPSFileListPluginResult!! error' + error)
          }

          lv_Vm.fn_StopListLoadCmplt()
        }, (error) => {
          // 실패콜백
          alert('getPSFileListPluginFalut!! error' + JSON.stringify(error))
          lv_Vm.fn_StopListLoadCmplt()
        })
    },



  /******************************************************************************
   * Function명 : fn_StopListLoadCmplt
   * 설명 : 중단건 영수증 번호 조회 완료
  ******************************************************************************/
    fn_StopListLoadCmplt () {
      if ( this.stopListVuchId.trim().length > 0 && this.stopListCnt > 1 ) {        
        this.fn_ServiceData('S4')        
      } else {
        this.fn_TableStat('EPSC064') // 전자서명 중단 건이 없습니다.
      }
    },

    
  /******************************************************************************
    * Function명  : fn_ServiceData
    * 설명        : 서비스 호출 함수 정의
   ******************************************************************************/
  fn_ServiceData (serviceName) {

    let trnstId = ''
    let pParams = null

    switch (serviceName) {      
      case 'S2': // 중단건 조회 
        window.vue.getStore('progress').dispatch('SKEL_LIST')//스켈레톤 리스트형 설정 
      case 'S2_N': // 중단건 페이징 조회
        if(serviceName == 'S2'){
          this.stopListPageNo = 1 // 신규조회는 1로 셋팅
          this.pSElstIntrsCseReqSVO2 = {}
          this.pSElstIntrsCseReqSVO2.predVuchId = this.stopListVuchId
          this.pSElstIntrsCseReqSVO2.pageNo     = this.stopListPageNo
          this.pSElstIntrsCseReqSVO2.pageSize   = 10
        } else {//페이지 증가처리
          let orgcpIntrsLength = (this.rsltOrgcpListIntrs)? this.rsltOrgcpListIntrs.length : 0
          if(orgcpIntrsLength >= this.stopListTotCnt || this.stopListTotCnt >= 10) return //페이징 마지막일때 호출차단
          this.stopListPageNo ++          
        }
        this.pSElstIntrsCseReqSVO2.pageNo = this.stopListPageNo
        pParams = this.pSElstIntrsCseReqSVO2
        trnstId = 'txTSSPS99S4'
        break     
      case 'S4': // 청약서 임시저장 삭제목록 조회
        this.pSElstPrgStatSVO = {}
        this.pSElstPrgStatSVO.predVuchId   = this.stopListVuchId
        this.pSElstPrgStatSVO.predDelDate  = PSCommUtil.fn_CurrntDayPlusDateChk(this.holyDayChkDateArry,5) // 워크데이 기준 D-5일까지 계피상이 중단건 유지 처리  
        this.pSElstPrgStatSVO.clctCnsltNo  = PSServiceManager.getUserInfo('userId')          
        pParams = this.pSElstPrgStatSVO
        trnstId = 'txTSSPS98S2'
        break        
    }//switch  
  
    if(! trnstId) return

    this.post(this, pParams, trnstId, 'S')
      .then((response)=>{        
        this.fn_ServiceDataRlstHandler(response,serviceName)
      })
      .catch((error)=>{
        window.vue.getStore('progress').dispatch('FULL')//스켈레톤 기본으로
        window.vue.error(error)
      })  

  },

  /******************************************************************************
    * Function명  : fn_ServiceDataRlstHandler
    * 설명        : 서비스 호출 결과 처리
   ******************************************************************************/
  fn_ServiceDataRlstHandler(response,serviceName){
    console.log('fn_ServiceDataRlstHandler !!!! serviceName = '+ serviceName +' / response =')    
    console.log(response)

    let rsltBody = (response && response.body)?response.body : null
    let refineList = null
    let i = 0
    let len = 0

    window.vue.getStore('progress').dispatch('PART')//스켈레톤 기본으로

    switch (serviceName) {
     
      case 'S2': // 중단건 조회
      case 'S2_N': //중단건 페이징 조회

        let t_rsltIntrs = (rsltBody && rsltBody.pSElstIntrsCseListDVO)? rsltBody.pSElstIntrsCseListDVO : []

        if ( serviceName === 'S2' ) {
          this.stopListTotCnt     = 0
          this.rsltOrgcpListIntrs = []
          this.rsltListIntrs      = []
        }

        this.stopListTotCnt = (rsltBody.totCnt)?rsltBody.totCnt : 0

       if (t_rsltIntrs.length == 0 ){
          if (serviceName == 'S2') this.fn_TableStat('EPSC064') // 전자서명 중단 건이 없습니다.
          return  
        } 

        refineList = this.fn_RefineRsltDataIntrs(t_rsltIntrs)
        
        refineList.forEach(item => {
          this.rsltOrgcpListIntrs.push(item)
          this.rsltListIntrs.push(item)
        })

        this.$nextTick( () => { // DOM의 상태가 변경 될 때 호출
          for(let i = 0 ; i < this.rsltOrgcpListIntrs.length; i++){
            this.$refs['expItem_'+i].isExpand = true // 아코디언 펼침 처리
          }
        });
        
        let isSortReset = true
        this.$emit('on-service-result',this.idx,this.rsltListIntrs,isSortReset)

        break     
      case 'S4': // 청약서 임시저장 삭제목록 조회 결과
        if ( rsltBody && rsltBody.pSElstPrgStatDVO && rsltBody.pSElstPrgStatDVO.length > 0 ) {
          console.log('response.body.pSElstPrgStatDVO:::' + JSON.stringify(response.body.pSElstPrgStatDVO))          
          let tempJoinArray = [];
          len = rsltBody.pSElstPrgStatDVO.length           
          for (i = 0; i < len ; ++i ) {
            tempJoinArray.push(rsltBody.pSElstPrgStatDVO[i].vuchId)            
          }//for 
          this.stopDeleteListVuchId = tempJoinArray.join() // 1,2,....           
          console.log('this.stopDeleteListVuchId:::' + this.stopDeleteListVuchId)          
          this.fn_StopListNoDeleteLocal()
        }else { // 청약서 임시저장 삭제목록 조회 결과 없음          
          this.fn_ServiceData('S2') //중단건 조회  S4 호출은 신규조회시에만 호출됨 S2 호출 불필요
        }
        
        break            
    }//switch  
    
  },
  
  /******************************************************************************
   * Function명  : fn_RefineRsltDataIntrs
   * 설명        : 중단건 조회 데이터 정제 처리
                   S2,S2_N 데이터 처리
  ******************************************************************************/
  fn_RefineRsltDataIntrs(rsltDataList){
      
    let rtnList = []
    let contrInsrdSameFlag = false // 계피상이 여부
    let contrElstFlag = false // 계약자 전자서명여부
    let insrdElstFlag = false // 피보험자 전자서명여부
    let dpstrElstFlag = false // 예금주 전자서명여부
    let dpstrIdpnFlag = false // 예금주독립여부

    rsltDataList.forEach(pItem => {

      // 설계명에 삼성생명, 삼성 제거 처리
      pItem.mnPrdtNm    = (pItem.mnPrdtNm)? pItem.mnPrdtNm.replace('삼성생명', '').replace('삼성', '').trim() : ''
      pItem.insrOfrYmd = (pItem.insrOfrYmd)? pItem.insrOfrYmd.trim() : ''
      pItem.insrOfrYmd = (pItem.insrOfrYmd.length > 0)? PSDateUtil.fn_DateFormat(pItem.insrOfrYmd, 'MM-dd') : '-'
      pItem.insrOfrYmdSort = (pItem.insrOfrYmd.length > 0)? PSDateUtil.fn_DateFormat(pItem.insrOfrYmd, 'yyy-MM-dd') : ''

      contrInsrdSameFlag = (pItem.contrInsrdSameYn === 'Y')? true : false // 계피상이 여부('Y' -> 계피동일)
      contrElstFlag      = (pItem.contrElstYn === 'Y')? true : false // 계약자 전자서명여부
      insrdElstFlag      = (pItem.insrdElstYn === 'Y' || pItem.mnisdMiorYn === 'Y')? true : false// 피보험자 전자서명여부 / 주피보험자미성년자여부 !== Y
      dpstrElstFlag      = (pItem.dpstrElstYn === 'Y')? true : false // 예금주 전자서명여부
      dpstrIdpnFlag      = (pItem.dpstrIdpnYn === 'Y')? true : false // 계약자=예금주 상이 여부

      if (contrInsrdSameFlag) { // 1 계피동일
        
        if (dpstrIdpnFlag) { //1.1 계약자=예금주 상이
          
          if (! contrElstFlag && ! dpstrElstFlag) { // 1.1.1 계약자 전자서명 X, 예금주 전자서명X            
            pItem.ofrMmgNm    = '열기'
            pItem.prcssStatus = '임시저장'
          } else if (! contrElstFlag && dpstrElstFlag) { // 1.1.2 계약자 전자서명X, 예금주 전자서명O            
            pItem.ofrMmgNm    = '계약자'
            pItem.prcssStatus = '임시저장'
          } else if (contrElstFlag && !dpstrElstFlag) { // 1.1.3 계약자 전자서명O, 예금주 전자서명X            
            pItem.ofrMmgNm    = '예금주'
            pItem.prcssStatus = '임시저장'
          } else if (pItem.elstPrgStatCd === '10') { // 1.1.4 기타경우            
            pItem.ofrMmgNm    = '열기'
            pItem.prcssStatus = '임시저장'          
          } else {
            pItem.ofrMmgNm    = '재전송'
            pItem.prcssStatus = '전송실패'
          }

        } else { // 1.2 계약자=예금주 동일

          if (contrElstFlag) { // 1.2.1 계약자 서명완료            
            if (pItem.elstPrgStatCd === '10') {
              pItem.ofrMmgNm    = '열기'
              pItem.prcssStatus = '임시저장'
            } else {
              pItem.ofrMmgNm    = '재전송'
              pItem.prcssStatus = '전송실패'
            }
          } else { // 1.2.2 계약자 서명 미완료            
            pItem.ofrMmgNm    = '열기'
            pItem.prcssStatus = '임시저장'
          } 

        }// end 1.2 계약자=예금주 동일

      }// end 1 계피동일 

      if (! contrInsrdSameFlag){ // 2 계피상이

        if (dpstrIdpnFlag) { // 2.1 계피상이 , 계약자 <> 예금주
        
          if (!contrElstFlag && !insrdElstFlag && !dpstrElstFlag) { // 2.2.1 계약자 전자서명X, 피보험자 전자서명X, 예금주 전자서명X          
            pItem.ofrMmgNm = '열기'
            pItem.prcssStatus = '계피상이'
          } else if (!contrElstFlag && !insrdElstFlag && dpstrElstFlag) { // 2.2.2 계약자 전자서명X, 피보험자 전자서명X, 예금주 전자서명O          
            pItem.ofrMmgNm = '계약자/피보험자'
            pItem.prcssStatus = '계피상이'
          } else if (!contrElstFlag && insrdElstFlag && !dpstrElstFlag) { // 2.2.3 계약자 전자서명X, 피보험자 전자서명O, 예금주 전자서명X          
            pItem.ofrMmgNm = '계약자/예금주'
            pItem.prcssStatus = '계피상이'
          } else if (!contrElstFlag && insrdElstFlag && dpstrElstFlag) { // 2.2.4 계약자 전자서명X, 피보험자 전자서명O, 예금주 전자서명O          
            pItem.ofrMmgNm = '계약자'
            pItem.prcssStatus = '계피상이'
          } else if (contrElstFlag && !insrdElstFlag && !dpstrElstFlag) { // 2.2.5 계약자 전자서명O, 피보험자 전자서명X, 예금주 전자서명X          
            pItem.ofrMmgNm = '피보험자/예금주'
            pItem.prcssStatus = '계피상이'
          } else if (contrElstFlag && !insrdElstFlag && dpstrElstFlag) { // 2.2.6 계약자 전자서명O, 피보험자 전자서명X, 예금주 전자서명O          
            pItem.ofrMmgNm = '피보험자'
            pItem.prcssStatus = '계피상이'
          } else if (contrElstFlag && insrdElstFlag && !dpstrElstFlag) { // 2.2.7 계약자 전자서명O, 피보험자 전자서명O, 예금주 전자서명X          
            pItem.ofrMmgNm = '예금주'
            pItem.prcssStatus = '계피상이'
          } else if (pItem.elstPrgStatCd === '10') { // 2.1.8 기타경우          
            pItem.ofrMmgNm = '열기'
            pItem.prcssStatus = '계피상이'
          } else {
            pItem.ofrMmgNm = '재전송'
            pItem.prcssStatus = '전송실패'
          }

        } else {  // 2.1 계피상이 , 계약자 = 예금주

          if (!contrElstFlag && !insrdElstFlag) { // 2.2.1 계약자 전자서명X, 피보험자 전자서명X          
            pItem.ofrMmgNm = '열기'
            pItem.prcssStatus = '계피상이'
          } else if (!contrElstFlag && insrdElstFlag) { // 2.1.2 계약자 전자서명X, 피보험자 전자서명O          
            pItem.ofrMmgNm = '계약자'
            pItem.prcssStatus = '계피상이'
          } else if (contrElstFlag && !insrdElstFlag) { // 2.1.3 계약자 전자서명O, 피보험자 전자서명X          
            pItem.ofrMmgNm = '피보험자'
            pItem.prcssStatus = '계피상이'
          } else if (pItem.elstPrgStatCd === '10') { // 2.1.4 기타경우          
            pItem.ofrMmgNm = '열기'
            pItem.prcssStatus = '임시저장'        
          } else {
            pItem.ofrMmgNm = '재전송'
            pItem.prcssStatus = '전송실패'
          }

        } //end 2.1 계피상이 , 계약자 = 예금주

      }// end 2 계피상이

      if (pItem.ofrMmgNm === '재전송' && pItem.abnrmCmpltYn === 'Y') {
        pItem.prcssStatus = '비정상종료'
      }

      //청약 이어하기  붙이기
      pItem.ofrMmgNm2 = pItem.ofrMmgNm

      if(pItem.ofrMmgNm !== '열기' && pItem.ofrMmgNm !== '재전송'){
        pItem.ofrMmgNm2 = pItem.ofrMmgNm2 + ' 청약 이어하기'  
      }

      rtnList.push(pItem)
    }) // forEach  

    return rtnList
  },  



 /******************************************************************************
   * Function명 : fn_StopListNoDeleteLocal
   * 설명 : 청약서임시저장삭제목록 데이터를 신태블릿 앱 캐시 영역과 
   *        전자서명 앱에 저장되어있는 파일리스트와 비교후 
   *         삭제목록이 아닌 목록리턴
  ******************************************************************************/
  fn_StopListNoDeleteLocal () {
    let lv_Vm = this
    let param = {list: this.stopDeleteListVuchId}

    // 보험거래문서디지털화 : OZ용 plugin 적용
    window.fdpbridge.exec('delTmpStorePlugin', param ,
      (result) => {
        // 성공콜백  1283479247,123487234,1234243434 형식의 영수증 번호만 옴
        console.log('delTmpStorePlugin result:::' + JSON.stringify(result))
        try {
          if ( result.data !== null && result.data.trim().length > 0 ) {
            lv_Vm.stopListVuchId = result.data
            console.log('lv_Vm.stopListVuchId:::' + lv_Vm.stopListVuchId)
            lv_Vm.fn_ServiceData('S2')
          } else {
            console.log('lv_Vm.stopListVuchId:::' + lv_Vm.stopListVuchId)
            lv_Vm.fn_TableStat('EPSC060') // 검색결과가 존재하지 않습니다
          }
          // alert('삭제목록 조회목록 - ' + lv_Vm.stopListVuchId)
        } catch (error) {
          window.vue.error(error)
        }
      }, (error) => {
        window.vue.error(error)
    })
  },
 


  /*---------------------------------------------------------------------------------
  * 기타 Util 영역
  ---------------------------------------------------------------------------------*/

  /******************************************************************************
   * Function명  : fn_TableStat
   * 설명        : 데이터 없는 페이지 설정
   this.$t('ps')['EPSC012']
  *******************************************************************************/
  fn_TableStat (mesgTextCd,log =''){    
    let val = this.$t('ps')[mesgTextCd]
    console.log(">>>fn_TableStat mesgText : "+val)
    this.mesgText = val
    
    this.rsltListIntrs = []
    
    let isSortReset = true
    this.$emit('on-service-result',this.idx,this.rsltListIntrs,isSortReset)

  },

  //--------------------------------------------------------------------------------

    _fn_End(){return}//코딩 종료 함수 마지막 메서드 함수 , 없음 주의
 },// methods

}//export default


</script>