/*
 * 업무구분 : 청약
 * 화면 명 : MSPPS241D
 * 화면 설명: 전자서명 완료건
            청약 파트 전자서명(중단건,재처리)
 *           
 */
<template>
  <ur-page-container class="msp" :show-title="false" :topButton="false">
    <ur-box-container v-if="!isNodata" alignV="start" componentid="" direction="column" class="status-list type-expand">
      <mo-list :list-data="rsltListCmplt">
        <template #list-item="{item,index}">      
          <mo-list-item class=""> 
            <!-- expanded expand-only-btn-->
            <msp-expand btn-area-first title-first expandable :preventScrollWhenExpand="true" :ref="'expItem_'+index" class="mo-list-expand w100" btn-area-class="fexTy3 align-item-start"> 
              <template #title>
                <div class="list-item__contents">
                  <div class="list-item__contents__title pt6">
                    <!-- 상품명 -->
                    <span class="name txtSkip fs19rem fwb">{{item.mnPrdtNm}}</span>
                  </div>
                  <div class="list-item__contents__info mt6">
                    <span><span class="fs14rem mr6 crTy-bk7">피보험자</span><span class="crTy-bk1 fs16rem txtSkip--psjjh maxW70 min70 ls--1 underline" @click="fn_PopupCustNmUiCard(item.insrdChnlCustId)">{{item.insrdNm === ' ' ? item.mnContrNm : item.insrdNm}}</span></span>
                    <span><em class="em_normal">|</em><span class="fs14rem mr8 crTy-bk7">청약일</span><span class="crTy-bk1 fs16rem ls--1">{{(item.elstPblYmd)? item.elstPblYmd : '-' }}</span></span>
                  </div>
                  <div class="list-item__contents__info mt6 mb10">
                    <span><span class="fs14rem mr8 crTy-bk7">보험료</span><span class="crTy-bk1 fs16rem fwb crTy-blue3">{{$bizUtil.numberWithCommasCurr(item.contPrm, item.crncyCd, 'Y', 'Y', 4)}}원</span></span>
                  </div>
                </div>
              </template>
              
              <template #btn>
                <mo-button class="link-arrow down mt10"></mo-button>
              </template>
              <template #content>
                <div class="list-item-detail gray_style bdnone margin--full pt20 pb20">
                  <ur-box-container class="con--padding ">
                    <div class="contents-row">
                        <span class="dsInline crTy-bk7 fs14rem mr20 min60">계약자</span><span class="crTy-bk1 fs16rem txtSkip--psjjh maxW70 min70 ls--1 underline" @click="fn_PopupCustNmUiCard(item.mobslChnlCustId)">{{item.mnContrNm}}</span>
                    </div>
                    <div class="contents-row mt4">
                        <span class="dsInline crTy-bk7 fs14rem mr20 min60">영수증번호</span><span class="fs16rem">{{item.vuchId}}</span>
                    </div>
                    <div class="contents-row">
                        <span class="dsInline crTy-bk7 fs14rem mr20 min60">교부방식</span><span class="crTy-bk1 fs16rem txtSkip--psjjh min70 ls--1">{{item.fstIsuMthScNm}}</span>
                    </div>
                  </ur-box-container>
                </div>
              </template>
            </msp-expand>
          </mo-list-item>
          <ur-box-container alignV="start" componentid="" direction="column" class="ns-input-box expand_bottomline">
            <div class="ns-btn-relative-area mt0">
              <div class="ns-btn-relative-s">
                <div class="relative-div mb5 fex-rw">
                  <!--계약서류 발송-->
                  <mo-button componentid="" class="ns-btn-round white ml0 mr0 flex-1" @click="fn_RTransOpenPopup(item)">계약서류</mo-button>
                  <!-- 청약관리 -->
                  <mo-button componentid="" class="ns-btn-round white ml0 mr0 flex-1" v-if="item.ofrMmgNm == '접수/출금'" @click="fn_MovAcpnDfra(item)">접수/출금</mo-button>
                  <mo-button componentid="" class="ns-btn-round white ml0 mr0 flex-1" v-else-if="item.ofrMmgNm === '접수불가'" @click="fn_PopupAcpnDalw(item.scnAcpnDalwRsnCntnt)">접수불가</mo-button>
                  <!-- <mo-button componentid="" class="ns-btn-round white ml0 mr0 flex-1" v-else disabled>{{item.ofrMmgNm}}</mo-button> -->
                  <!-- 진행현황 -->
                  <mo-button componentid="" class="ns-btn-round white ml0 mr0 flex-1" v-if="item.prgPhseNm === '재처리'" @click="fn_PopupRtryTrt(item)">재처리</mo-button>
                  <!-- <mo-button componentid="" class="ns-btn-round white ml0 mr0 flex-1" v-else disabled>{{item.prgPhseNm}}</mo-button> -->
                </div>
              </div>
            </div>
          </ur-box-container>
        </template>
      </mo-list>
    </ur-box-container>
    
    <!-- 완료건 NoData 영역 ur-box-containe  -->        
    <PSNodataMesgBoxForList v-if="isNodata" ref="nodataMesgBox" />        
    <!-- / 완료건 NoData 영역 ur-box-containe -->  

  </ur-page-container>
</template>

<script>
/***********************************************************************************
* INCLUDE 영역
***********************************************************************************/
import Msg from '@/systems/webkit/msg/msg'
import { createScrollAnimateInstance } from '@/utils/scroll/scroll-animate'
import PSCommUtil from '@/ui/ps/comm/PSCommUtil'
import PSDateUtil from '@/ui/ps/comm/PSDateUtil'
import PSConstants from '@/config/constants/psConstants'
import PSServiceManager from '@/ui/ps/comm/PSServiceManager' 
import PSNodataMesgBoxForList from '@/ui/ps/comp/PSNodataMesgBoxForList'
import MSPPS241P from '@/ui/ps/MSPPS241P'
import MSPPS234P from '@/ui/ps/MSPPS234P'

export default {

  /***********************************************************************************
  * Vue 파일 속성 정의 영역
  * - name : 화면ID동일 영문만 기입 특수문자 한글 기입시 에러남
  * - screenId : 화면ID
  * - components : UI TAG 컴포넌트 정의
  ***********************************************************************************/
  name: 'MSPPS241D', 
  screenId: 'MSPPS241D',

  components: {
    'PSNodataMesgBoxForList' : PSNodataMesgBoxForList , // mo-list 전용 데이터 없음 박스
    'MSPPS241P' : MSPPS241P, // 전자서명완료 재처리 팝업
    'MSPPS234P' : MSPPS234P // 설계청약 계약서류발송
  },//componets,

  /***********************************************************************************
  * 화면 UI Property 정의
  * - 파리미터 받는 변수 props 정의
  ***********************************************************************************/
  props:{
    idx                : {type:Number, default:-1}, // 부여받은 탭 인덱스
    curntTabIdx        : {type:Number, default:-1}, // 부모 선택한 현재 탭 인덱스
    holyDayChkDateArry : {type:Array,  default:()=>{ return [] }}  // 현재날짜+10일까지 날짜정보 배열  부모로 부터 전달 받음 

  },//props

  /***********************************************************************************
  * 팝업일때 팝업 활성 형태 속성 
  * - 풀 팝업 형태 일때 mode 값 설정
  ***********************************************************************************/
  /*
  modalProps: {
    mode: 'right' // 우->좌 open popup (defalut: 하->상)
  },
  */

  /***********************************************************************************
  * 화면 전역 변수 선언 영역
  * - UI 바인딩 및 스크립트 화면 전역변수 정의
  ***********************************************************************************/
  data () {
    return {
      PSConstants   : PSConstants, // 청약 상수
      srchOptData   : null ,       //검색 옵션
      mesgText      : '',          // 데이터 없음 표시 메세지      
      rsltCnt       : 0,          // 출력리스트 갯수
      isNodata      : false,      // 데이터 없음 Flag
      sortFieldListCmplt : [] ,   // 완료건 sort 필드 List
      rsltListCmplt      : [],    // 완료건 조회결과 출력 데이터 List      
      rsltOrgcpListCmplt : [],    // 완료건 조회결과 원본 데이터 List
      itemVuchId      : '',       // 선택한 전자서명 진행건 영수증번호 , 전자서명 현장접수/현장출금시 버튼 클릭시 셋팅됨      
      scnAcpnCmpltYn  : '',       // 현장접수완료여부 , 전자서명 현장접수/현장출금시 버튼 클릭시 셋팅됨
      pSElstIntrsCseReqSVO1 : {}, //완료건 페이징 파라미터셋 정보
      eltrnSignPageNo  : 1,       //완료건 현재 페이지
      eltrnSignTotCnt  : 0,       //완료건 조회 총 레코드 개수
      itemContNo       : '',      // 완료건 선택한 Item 계약번호 
      retryPopup       : null ,   // 전자서명 진행단계 채처리 팝업      
      _temp : null,
      alert1: false, // 보완 (ASR240700733/240805_PJO)
      alert2: false // 보완 (ASR240700733/240805_PJO)
    }
  },//data

  /***********************************************************************************
  * 컴포넌트 BASE 라우터 네비게이션 가드 함수 정의영역
  * - 화면전환 Route Life Cycle 함수
  * - 주로 next(fals) 진행불가처리 ,next()진행 처리 스크립트 컨트롤용도
  *   사용시 함수 주석 풀고 스크립트 작성 할것  
  ***********************************************************************************/
  
  //router에 의해 화면진입시 호출 인스턴스 생성되기전이라 this 자원 접근안됨  
  //beforeRouteEnter (to, from, next) { 반드시  next(fals) OR next()  호출 },//beforeRouteEnter

  //router에 의해 화면을 벗어날때 호출 
  //beforeRouteLeave (to, from, next) { 반드시  next(fals) OR next()  호출 },//beforeRouteLeave


 /***********************************************************************************
  * Life Cycle 함수 정의 영역
  * - 각단계별 예약 함수에 맞게 스크립트 작성       
  ***********************************************************************************/

  // Life Cycle Step-1 인스턴스 초기화 후 데이터 관찰/이벤트/감시자 설정 전 호출 - 호출 부모 우선
  beforeCreate () {},//beforeCreate

  // Life Cycle Step-2 인스턴스가 작성된 후 동기적 호출 - this 접근 가능, el 접근 불가 - 호출 부모 우선  
  created () {
    this.sortFieldListCmplt = [
         {field : 'elstPblYmdSort'  , label : '청약일'     , sort: 'DESC'}
        ,{field : 'insrdNm'         , label : '피보험자'    , sort: 'ASC'}
        ,{field : 'mnContrNm'       , label : '계약자'      , sort: 'ASC'}
      ]
  },//created

  // Life Cycle Step-3 마운트되기 바로 직전 전 호출 - this 접근 가능, el 접근 불가 - 호출 자식 우선
  beforeMount () {},//beforeMount

  // Life Cycle Step-4 마운트 된 후 호출 - 여기서 부터 모든 자원 접근 가능 - 호출 자식 우선
  // 이곳에서 초기설정 스크립트 작성
  mounted () {
    //부모로 부터 sort change event 등록
    this.$on('on-sort-change',this.fn_SortHandler)
    
    //부모로 부터 on-scroll-bottom 이벤트 등록 
    this.$on('on-scroll-bottom',this.fn_ScrollBottomHandler)

    //부모로 부터 on-search 이벤트 등록
    this.$on('on-search',this.fn_SearchEvent)

    //부모에게 mounted 이벤트 발생
    this.$emit('on-child-tab-mounted',this)
    
  },//mounted

  // Life Cycle Step-5 props 정의된 데이터가 변경되고 렌더링 되기 전 호출되는 수시호출함수
  beforeUpdate () {},//beforeUpdate

  // Life Cycle Step-6 props 데이터가 변경되고 렌더링 업데이트 후 호출되는 수시호출함수
  updated () {},//updated

  // Life Cycle Step-7 인스턴스가 Remove 되기 전 호출
  beforeDestroy () {},//beforeDestroy

  // Life Cycle Step-8 인스턴스가 Remove 된 후 호출
  destroyed () {
    this.$off('on-sort-change',this.fn_SortHandler)
    this.$off('on-scroll-bottom',this.fn_ScrollBottomHandler)
    this.$off('on-search',this.fn_SearchEvent)
  },//destroyed

  
  /***********************************************************************************
   * watch 정의 영역
   * - data 영역에 바인딩 변수 정의한 값에 대한 값 변경시 지정 호출 함수 정의 
   *   (변수에 대한 값 변경시 반응형 콜백 처리)
   *   ex) data 영역 정의한 익명변수 userNm 
   *       userNm : function() { this.message + ' 님 안녕하세요!'}
  ***********************************************************************************/
  watch: {
     rsltListCmplt(){
      this.rsltCnt   = this.rsltListCmplt ? this.rsltListCmplt.length : 0
      this.isNodata  = this.rsltCnt == 0 ?  true : false
    }
  },//watch

 /***********************************************************************************
  * Computed 함수 정의 영역
  * - data 영역에 바인딩 변수 정의한 값에 대한 값 변경시 지정 호출 getter 함수 정의
  *   지정 함수는 반드시 리턴 하는 함수로 작성
  *   (변수에 대한 값 변경시 반응형 getter 함수 지정 처리)
  *   ex) data 영역 정의한 익명변수 userNm 
  *   userNm : function() { return this.userNm = this.userNm? this.userNm.trim() : '' } 
  ***********************************************************************************/
  computed: {},//computed

 /***********************************************************************************
  * 사용자 함수 정의 영역
  ***********************************************************************************/

  methods: {
    
  /*---------------------------------------------------------------------------------
  * UI 이벤트 핸들러 영역
  ---------------------------------------------------------------------------------*/

    /******************************************************************************
    * Function명  : fn_SortHandler
    * 설명        : Sort 처리 이벤트 핸들러
    *               pSortOrder = {field : 'title' , label : '제목' , sort: 'ASC' or 'DESC'}
    ******************************************************************************/
    fn_SortHandler(pSortOrder) {
      console.log('MSPPS241D 완료건 fn_SortHandler !!!',pSortOrder)

      let sortField = pSortOrder.field
      let sortOrderby = pSortOrder.sort //ASC or DESC
      
      switch(sortField){       
        case 'elstPblYmdSort': // 청약일 날짜형 String 을 Date 형으로 변환 처리 해서 sort
          PSCommUtil.fn_SortListValueDateString(sortOrderby, this.rsltListCmplt, sortField, 'yyyy-MM-dd') //yyyy-MM-dd
          break
        case 'insrdNm':  //피보험자 String 형 sort
        case 'mnContrNm':  //계약자 String 형 sort
          PSCommUtil.fn_SortListValueString(sortOrderby, this.rsltListCmplt, sortField)
          break
      }//switch
    },
    /******************************************************************************
    * Function명  : fn_ScrollBottomHandler
    * 설명        : Scroll Bottom 핸들러
    ******************************************************************************/
    fn_ScrollBottomHandler() {
       this.fn_ServiceData('S1_N')
    },


  /*---------------------------------------------------------------------------------
  * UI Popup 관련 처리 영역
  ---------------------------------------------------------------------------------*/

    /******************************************************************************
     * Function명 : fn_PopupCustNmUiCard
     * 설명 : 계약자 피보험자 클릭시 nameUI 실행하는 함수
     ******************************************************************************/
    fn_PopupCustNmUiCard (pChnlCustId) {
      let nmUiObject = {
        chnlCustId : pChnlCustId, //고객채널 ID
        cnsltNo    : PSServiceManager.getUserInfo('userId'),  // FC 사번
        parentId   : this.$options.screenId //화면ID]
      }      
      this.$cmUtil.fn_OpenCustNmUiCard(this, nmUiObject, (modalId)=>{
        //Close 콜백 처리
        return
      })


    },

    /******************************************************************************
     * Function명 : fn_PopupAcpnDfra
     * 설명 : 완료건 접수/출금 처리 화면 이동
     ******************************************************************************/
    fn_MovAcpnDfra (slctItem){
      this.slctItamData = slctItem

      this.itemContNo     = this.slctItamData.contNo
      this.itemVuchId     = this.slctItamData.vuchId
      this.scnAcpnCmpltYn = this.slctItamData.scnAcpnCmpltYn

      this.fn_ServiceCall('S1')
    },

    /******************************************************************************
     * Function명 : fn_PopupAcpnDfra
     * 설명 : 완료건 접수불가 상태 팝업
     ******************************************************************************/
    fn_PopupAcpnDalw (showMesg){
      // showMsg가 공백인경우 : 200221, 모바일영업지원 오픈이전 접수불가건은 메시지를 저장하지 않음
      if (showMesg.trim() === '') {        
        showMesg = '지점에서 접수 가능합니다.'
      }

      this.getStore('confirm').dispatch('ADD', showMesg)
    },

    /******************************************************************************
     * Function명 : fn_PopupRtryTrt
     * 설명 : 완료건 전자서명 진행단계 재처리 상태 팝업
     ******************************************************************************/
    fn_PopupRtryTrt (slctItem){
      this.slctItamData = slctItem

      this.retryPopup = this.$bottomModal.open(MSPPS241P, {
        properties: {
          rowPopupData : this.slctItamData
        },
        listeners: {
          // 대상팝업 onPopupConfirm $emit 이벤트명 
          onPopupConfirm: (rtnData) => {
            this.$bottomModal.close(this.retryPopup)     // 모달 닫기
          }          
        }
      })

    },

  /*---------------------------------------------------------------------------------
  * Data Service 핸들러 영역
  ---------------------------------------------------------------------------------*/

  /************************************************************************************************
   * Function명  : fn_RTransOpenPopup
   * 설명        : 팝업
   ************************************************************************************************/
  fn_RTransOpenPopup: function (slctItem) {
    this.slctItamData = slctItem
    let lv_Vm = this
    
    this.rpblPopup = this.$bottomModal.open(MSPPS234P, {
      properties: {
        pParntSrnId: this.$options.screenId, // 부모 화면 ID          
        pRowPopupData : this.slctItamData
      },
      listeners: { 
        // 대상팝업 onPopupCancel $emit 이벤트명 
        onPopupCancel : (rtnData) => {            
          this.$bottomModal.close(this.rpblPopup) // 모달 닫기   
          
        },
        // 대상팝업 onPopupClose $emit 이벤트명 
        onPopupClose : (rtnData) => {            
            this.$bottomModal.close(this.rpblPopup) // 모달 닫기        
        }
      }
    })

  },


  /******************************************************************************
    * Function명  : fn_SearchEvent
    * 설명        : 완료건 조회 케이스 처리
    *               고객명 / 상품명(필터) / 영수증번호(필터) 
                    검색 1 : 리스트출력 2 방식으로 동시호출로 편작
    ******************************************************************************/
  fn_SearchEvent (pSrchOptData) {
    this.srchOptData = pSrchOptData
    
    let srchSc        = (this.srchOptData)? this.srchOptData.searchScVal : '1' //검색 구분 (1:고객명,2:상품명,3:영수증번호)
    let chnlCustId    = (this.srchOptData)? this.srchOptData.chnlCustId : '' //고객명으로 검색시 선택한 채널 고객id
    let searchKeyword = (this.srchOptData)? this.srchOptData.searchKeyword : '' //검색 키워드 (고객명, 상품명 , 영수증번호)


    //서비스 호출 여부 판단
    if(! searchKeyword) { // 검색어 공백으로 검색시 신규조회 
       this.fn_ServiceData('S1')//완료건 조회
    } else if(this.rsltOrgcpListCmplt) { //마지막 조회된 원본 데이터로 필터 처리

      this.rsltListCmplt = []

      this.rsltOrgcpListCmplt.forEach(item => {        
        switch (srchSc) {
          case PSConstants.PLAN_OFR_SEARCH_OPT_CUST_NM.key: //1:고객명             
            if ( chnlCustId &&  chnlCustId === item.mobslChnlCustId || chnlCustId === item.insrdChnlCustId ) {
                this.rsltListCmplt.push(item)
            }            
            break
          case PSConstants.PLAN_OFR_SEARCH_OPT_PRDT_NM.key: //2:상품명
            if (item.mnPrdtNm.indexOf(searchKeyword) > -1) {
              this.rsltListCmplt.push(item)
            }
            break
          case PSConstants.PLAN_OFR_SEARCH_OPT_VUCH_NUM.key: //3:영수증번호
            if (item.vuchId.indexOf(searchKeyword) > -1) {
              this.rsltListCmplt.push(item)
            }
            break        
        }//switch
      }) //forEach this.rsltOrgcpListCmplt

      if(this.rsltListCmplt.length == 0){
        this.fn_TableStat('EPSC060') //검색결과가 존재하지 않습니다
      }else{
         let isSortReset = false 
         this.$emit('on-service-result',this.idx,this.rsltListCmplt,isSortReset)
      } 
    }  
        
  },

    /******************************************************************************
    * Function명  : fn_ServiceCall
    * 설명        : 레거시&인터페이스 서비스 콜
    ******************************************************************************/
    fn_ServiceCall (serviceName) {
      
      let pParams = {}
      
      switch (serviceName) {
        case 'S1': // fn_MovAcpnDfra 호출 접수/출금 처리 화면 이동전 현장접수 현장출금 상태 조회
          pParams.appId = ''
          pParams.trnstId = ''
          pParams.fnctScCd = 'S'
          pParams.serviceId = 'C339_F1PQL0346_S'
          pParams.reqVONm = 'sli.in.ea2.vo.a0025pq.EAF1PQL0346VO'
          pParams.resVONm = 'sli.in.ea2.vo.a0025pq.EAF1PQL0346VO'
          pParams.srnId = this.$options.screenId // 대표 화면 명
          pParams.data = {}
          pParams.data.zNcontTrtStatMobIfDto = {planId: this.itemVuchId}
          
          break
        default:
          break
      }

      PSServiceManager.invoke(pParams, 
        this.fn_ServiceCallRsltHandler, 
        serviceName, 
        (event)=>{
          //fault event
          this.getStore('confirm').dispatch('ADD', event.msgDesc)  
      })
    },
  
  
   /******************************************************************************
    * Function명  : fn_ServiceCallRsltHandler
    * 설명        : fn_ServiceCall 호출서비스 호출결과 함수
    ******************************************************************************/
    fn_ServiceCallRsltHandler (event, serviceName) { //
 
      let rsltData = (event && event.data)? event.data : null
      let alertMesg = ''
      let trtYmd03 = 'N' // 접수일 여부 판단
      let trtYmd04 = 'N' // 출금일 여부 판단
      let findItem = null
      
      switch (serviceName) {        
        case 'S1': // fn_MovAcpnDfra 호출 접수/출금 처리 화면 이동전 현장접수 현장출금 상태 조회
          if (! rsltData || ! rsltData.zncontTrtStatMobIfDto) break //switch break 

          for ( let i = 0; i < this.rsltListCmplt.length; i++ ) {
            if ( this.rsltListCmplt[i].vuchId === this.itemVuchId ) {
              findItem = this.rsltListCmplt[i]
              break //for break
            }
          }// for

          if(!findItem) {
            alertMesg = '대상건없음!.'
            break //switch break
          }

          if ( rsltData.zncontTrtStatMobIfDto.ofrStatCd.trim() === '91' ) {
            alertMesg = '청약소멸건 입니다.'
            this.alert1 = true // 보완 (ASR240700733/240805_PJO)
          } else {
           // eslint-disable-next-line
            /*************************************************************************************************************************************************
            * 접수출금 버튼 터치시 상태확인
                - 신계약진행현황의 ZaTrtYmd03(접수일), ZaTrtYmd04(출금일) 날짜 유무 및 현장접수/출금 이력으로 상태 판단
                  1)ZaTrtYmd03(접수일) N, ZaTrtYmd04(출금일) N, 현장접수 이력 N → 현장접수 화면으로 이동
                  2)ZaTrtYmd03(접수일) N, ZaTrtYmd04(출금일) N, 현장접수 이력 Y → (접수취소건) 지점 접수 안내메세지,(alert1)초회보험료 이체신청일자 업데이트 후 '접수출금' -> '-' (화면에서 안보임)
                  3)ZaTrtYmd03(접수일) Y, ZaTrtYmd04(출금일) N, 현장접수 이력 N → "지점출금 안내"메세지 노출, (alert1)초회보험료 이체신청일자 업데이트 후 '접수출금' -> '-' (화면에서 안보임)
                  4)ZaTrtYmd03(접수일) Y, ZaTrtYmd04(출금일) N, 현장접수 이력 Y → 
                    1 RT, 9 신용카드 -> 현장출금 화면으로 이동  
                    4 가상계좌 : '가상계좌 접수건은 23시까지 보험료 입금을 완료하여 주시기 바랍니다.' 메세지 노출, (alert1)초회보험료 이체신청일자 업데이트 후 '접수출금' -> '-' (화면에서 안보임)
                    F 간편납입 : '카카오페이 납입 접수건은 23시까지 신청을 완료하여 주시기 바랍니다.' 메세지 노출, (alert1)초회보험료 이체신청일자 업데이트 후 '접수출금' -> '-' (화면에서 안보임)
                  5)ZaTrtYmd03(접수일) Y, ZaTrtYmd04(출금일) Y, 현장접수 이력 Y/N → '출금완료'메세지 노출, (alert1)초회보험료 이체여부 완료 업데이트 후 '접수출금' -> '-' (화면에서 안보임)
            *************************************************************************************************************************************************/
           
            let tempYmd03 = rsltData.zncontTrtStatMobIfDto.trtYmd03 ? rsltData.zncontTrtStatMobIfDto.trtYmd03.trim() : ''
            trtYmd03= tempYmd03.length > 0 ? 'Y' : 'N'

            let tempYmd04 = rsltData.zncontTrtStatMobIfDto.trtYmd04 ? rsltData.zncontTrtStatMobIfDto.trtYmd04.trim() : ''
            trtYmd04= tempYmd04.length > 0 ? 'Y' : 'N'           
            
            //this.scnAcpnCmpltYn // 현장접수완료여부
            if ( trtYmd03 === 'N' && trtYmd04 === 'N' && this.scnAcpnCmpltYn === 'N' ) { // 1)
              // 현장접수 화면으로 이동
              this.$MenuManager.fn_RouterPushSrnId('MSPPS610M', {reqNameID: 'MSPPS240M_1', itemData: findItem, step: '1'}) 
            } else if ( trtYmd03 === 'N' && trtYmd04 === 'N' && this.scnAcpnCmpltYn === 'Y' ) { // 2)
              // 2) 4월 15일 발행복원 기능 추가로 지점 취소와 구분이 불가능 하여 모두 지점 접수로 안내메세지 처리 하도록 수정
              // 지점 출금 안내 상태와 동일하여 구분이 되지 않게 됨.
              alertMesg = '초회보험료 미출금으로 접수취소되어<br />지점에서 재접수 가능합니다.'
              this.alert1 = true // 보완 (ASR240700733/240805_PJO)
            } else if ( trtYmd03 === 'Y' && trtYmd04 === 'N' && this.scnAcpnCmpltYn === 'N' ) { // 3)
              alertMesg = '지점 입금처리 대상 건입니다.<br />지점으로 연락 바랍니다.'
              this.alert1 = true // 보완 (ASR240700733/240805_PJO)
            } else if ( trtYmd03 === 'Y' && trtYmd04 === 'N' && this.scnAcpnCmpltYn === 'Y' ) { // 4)
              if (findItem.frtmPrmPadMthCd === '1' || findItem.frtmPrmPadMthCd === '9' ) { // 9 신용카드 (ASR240400333_24.05 장한나)
                //전자서명 완료 화면으로 이동(현장접수 화면으로 이동)                                
                this.$MenuManager.fn_RouterPushSrnId('MSPPS610M', {reqNameID: 'MSPPS240M_2', itemData: findItem, step: '2'}) // 현장출금 화면으로 이동
              } else if (findItem.frtmPrmPadMthCd === '4') {
                // 가상계좌의 경우 알림창 후 출금 불가 처리 오픈 이후 반영 예정
                alertMesg = '가상계좌 접수건은 23시까지 보험료 입금을 완료하여 주시기 바랍니다.' // 4/6 최주연선임 변경 요청 가상계좌 접수건은 지점에서 출금 가능합니다.' // 시간표기변경(ASR230901005_가상,카페20->23)(23.12.05_PJO)
                this.alert1 = true // 보완 (ASR240700733/240805_PJO)
              } else if (findItem.frtmPrmPadMthCd === 'F') { // ASR221100288_간편납입(카카오PAY)(23.02.21_PJO)
                alertMesg = '카카오페이 납입 접수건은 23시까지 신청을 완료하여 주시기 바랍니다.' // 시간표기변경(ASR230901005_가상,카페20->23)(23.12.05_PJO)
                this.alert1 = true // 보완 (ASR240700733/240805_PJO)
              }
            } else if ( trtYmd03 === 'Y' && trtYmd04 === 'Y' ) { // 5)
              alertMesg = '초회보험료 이체완료건입니다.'
              this.alert2 = true // 보완 (ASR240700733/240805_PJO)
            }
            
            // 지점접수 업데이트
            if ( trtYmd03 === 'Y' && this.scnAcpnCmpltYn === 'N' ) { 
              this.fn_ServiceData('S6')
            }
          }
          
          if(alertMesg){
            this.getStore('confirm').dispatch('ADD', alertMesg)
          }
          if(this.alert1){ // 보완 (ASR240700733/240805_PJO)
            this.frtmPrmTrsfYmd = '99999999'
            this.fn_ServiceData('S5') // 초회보험료 이체신청일자 업데이트
          }

          if(this.alert2){ // 보완 (ASR240700733/240805_PJO)
            this.fn_ServiceData('S3') // 초회보험료 이체여부 완료 업데이트
          }
          break
        default:
          break
      }
    },


    /******************************************************************************
      * Function명  : fn_ServiceData
      * 설명        : 서비스 호출 함수 정의
    ******************************************************************************/
    fn_ServiceData (serviceName) {

      let trnstId = ''
      let pParams = null
     
      switch (serviceName) {
        case 'S1': // 전자서명 진행 완료건 조회
          window.vue.getStore('progress').dispatch('SKEL_LIST')//스켈레톤 리스트형 설정       
        case 'S1_N': // 전자서명 진행 완료건 페이징 조회
          if(serviceName == 'S1'){
            this.eltrnSignPageNo = 1 // 신규조회는 1로 셋팅                    
            this.pSElstIntrsCseReqSVO1 = {}
            //this.pSElstIntrsCseReqSVO1.minusDay = String(365*2) //완료건 조회기간 변경 15일 -> 2년
            this.pSElstIntrsCseReqSVO1.minusDay = '100' // 2023-07-20 2년 -> 100일 변경
            this.pSElstIntrsCseReqSVO1.pageSize = 10
          } else {//페이지 증가처리
            let orgcpCmpltLength = (this.rsltOrgcpListCmplt)? this.rsltOrgcpListCmplt.length : 0
            if(orgcpCmpltLength >= this.eltrnSignTotCnt || this.eltrnSignTotCnt <= 10) return //페이징 마지막일때 호출차단
            this.eltrnSignPageNo ++
          }             
          this.pSElstIntrsCseReqSVO1.pageNo = this.eltrnSignPageNo
          pParams = this.pSElstIntrsCseReqSVO1
          trnstId = 'txTSSPS99S3'
          break
        case 'S3': // 초회보험료 이체여부 완료 업데이트
          pParams = {}
          pParams.elstPrgStatCd   = '40',
          pParams.fofDfraYn       = 'Y',
          pParams.frtmPrmCmpltYn  = 'Y',
          pParams.vuchId          = this.itemVuchId          
          trnstId                 = 'txTSSPS98U11'
          break        
        case 'S5': // 초회보험료이체신청일자 업데이트
          pParams = {}
          pParams.frtmPrmTrsfYmd = '99999999' //this.frtmPrmTrsfYmd,
          pParams.vuchId         = this.itemVuchId        
          trnstId                = 'txTSSPS98U8'
          break
        case 'S6': // 지점접수여부 업데이트
          pParams = {}
          pParams.vuchId    = this.itemVuchId,
          pParams.fofAcpnYn = 'Y'
          trnstId           = 'txTSSPS98U14'
          break      
      }//switch  
    
      if(! trnstId) return

      this.post(this, pParams, trnstId, 'S')
        .then((response)=>{        
          this.fn_ServiceDataRlstHandler(response,serviceName)
        })
        .catch((error)=>{
           window.vue.getStore('progress').dispatch('FULL')//동그란 지정이
          window.vue.error(error)
        })  

    },


  /******************************************************************************
    * Function명  : fn_ServiceDataRlstHandler
    * 설명        : 서비스 호출 결과 처리
   ******************************************************************************/
  fn_ServiceDataRlstHandler(response,serviceName){
    console.log('fn_ServiceDataRlstHandler !!!! serviceName = '+ serviceName +' / response =')    
    console.log(response)

    let rsltBody = (response && response.body)?response.body : null
    let refineList = null
    let i = 0
    let len = 0

     window.vue.getStore('progress').dispatch('PART')//동그란 지렁이
     
    switch (serviceName) {
      case 'S1': //완료건 조회        
      case 'S1_N': //완료건 페이징 조회

        let t_rslt = (rsltBody && rsltBody.pSElstIntrsCseListDVO)? rsltBody.pSElstIntrsCseListDVO : []

        if ( serviceName == 'S1' ) {
          this.eltrnSignTotCnt    = 0            
          this.rsltOrgcpListCmplt = []
          this.rsltListCmplt      = []
        } 

        this.eltrnSignTotCnt = (rsltBody && rsltBody.totCnt)? rsltBody.totCnt : 0

        if (t_rslt.length == 0 && serviceName == 'S1'){
           //초기화 조회시에만 
          if (serviceName == 'S1') this.fn_TableStat('EPSC061') // 진행중인 가입설계 건이 없습니다.
          //페이징 조회는  리턴 처리
          return  
        } 
        refineList = this.fn_RefineRsltDataCmplt(t_rslt) // 출력용 정제처리
        
        refineList.forEach(item => {
          this.rsltOrgcpListCmplt.push(item)
          this.rsltListCmplt.push(item)
        })

        this.$nextTick( () => { // DOM의 상태가 변경 될 때 호출
          for(let i = 0 ; i < this.rsltOrgcpListCmplt.length; i++){
            this.$refs['expItem_'+i].isExpand = true // 아코디언 펼침 처리
          }
        });

        let isSortReset = true
        this.$emit('on-service-result',this.idx,this.rsltListCmplt,isSortReset)
        break     
      case 'S3': // 초회보험료 이체여부 완료 업데이트
      case 'S5': // 초회보험료이체신청일자 업데이트  
        if(!rsltBody) return

        len = (this.rsltListCmplt)? this.rsltListCmplt.length : 0        
        for ( i = 0; i < len ; ++i ) {
          if ( this.rsltListCmplt[i].vuchId === this.itemVuchId ) {
            this.rsltListCmplt[i].ofrMmgNm = '-'
            break
          }
        }

        len = (this.rsltOrgcpListCmplt)? this.rsltOrgcpListCmplt.length : 0
        for ( i = 0; i < len ; ++i ) {
          if ( this.rsltOrgcpListCmplt[i].vuchId === this.itemVuchId ) {
            this.rsltOrgcpListCmplt[i].ofrMmgNm = '-'
            break
          }
        }
        break      
      case 'S6': // 지점접수여부 업데이트
        if(!rsltBody) return 
        break      
    }//switch  
    
  },


  /******************************************************************************
   * Function명  : fn_RefineRsltDataCmplt
   * 설명        : 완료건 조회 데이터 정제 처리
                   S1,S1_N 데이터 처리
  ******************************************************************************/
  fn_RefineRsltDataCmplt(rsltDataList){

    let rowIndex = (this.rsltListCmplt)?this.rsltListCmplt.length : 0 // 고유ID 식별 처리 필요
    let rtnList = []

    rsltDataList.forEach(pItem =>{

      pItem.id = rowIndex++
      pItem.step = 0

      // 설계명에 삼성생명, 삼성 제거 처리
      pItem.mnPrdtNm  = (pItem.mnPrdtNm)? pItem.mnPrdtNm.replace('삼성생명', '').replace('삼성', '').trim() : ''
      pItem.ofrMmgNm  = '-'
      pItem.prgPhseNm = '-'

      // 191115 현장접수 청약일 조건변경
      // 계약일 익영업일까지 접수 가능함
      // 1) 초회보험료납입코드 1:실시간이체 , 9:신용카드-> 계약일=출금동의일자(DFRA_CNSNT_YMD)
      // 2) 초회보험료납입코드 4:가상계좌입금, F:간편납입  -> 계약일=전자서명발행일자(청약일자)(ELST_PBL_YMD)

      if (pItem.frtmPrmPadMthCd === '1' || pItem.frtmPrmPadMthCd === '9' ) { // 9 신용카드 (ASR240400333_24.05 장한나)
        pItem.joinDate = pItem.dfraCnsntYmd // 1) 초회보험료납입코드 1:실시간이체 -> 계약일=출금동의일자(DFRA_CNSNT_YMD)
      } else { // // ASR221100288_간편납입(카카오PAY)(23.02.21_PJO)  // if (pItem.frtmPrmPadMthCd === '4') {
        pItem.joinDate = pItem.elstPblYmd // 2) 초회보험료납입코드 4:가상계좌입금, F:간편납입 -> 계약일=전자서명완료일자(ELST_CNCLN_YMD)
      }

      // 191231 ASR191200905 청약일자 공백時 전자서명 완료건 목록 미 표시
      pItem.elstPblYmd = (pItem.joinDate && pItem.joinDate.trim().length > 0) ? PSDateUtil.fn_DateFormat(pItem.joinDate, 'MM-dd') : '-'
      pItem.elstPblYmdSort = (pItem.joinDate && pItem.joinDate.trim().length > 0) ? PSDateUtil.fn_DateFormat(pItem.joinDate, 'yyyy-MM-dd') : ''
      
      // 01 : email, 02 : 서면 ,03 : 모바일+Email , 04 : 모바일
      if (pItem.isuMthScCd === '01') {
        pItem.fstIsuMthScNm = 'E-mail'
      } else if (pItem.isuMthScCd === '02') {
        pItem.fstIsuMthScNm = '서면'
      } else if (pItem.isuMthScCd === '03') {
        pItem.fstIsuMthScNm = '알림톡&이메일'//'모바일(e)'
      } else if (pItem.isuMthScCd === '04') {
        pItem.fstIsuMthScNm = '알림톡'//'모바일'
      } else {
        pItem.fstIsuMthScNm = '미선택'
      }  

      // 현장 접수 완료가 'Y'이고 초회보험료이체여부과 'N'일 때
      // 초회보험료이체일자 + 1 보다 같거나 작을 때 까지 현장출금 버튼 활성화
      if (pItem.elstPrgStatCd === '40') { // 전자서명진행상태코드(elstPrgStatCd) : 40:이체완료
        pItem.prgPhseNm = '-' // 이체완료
      } else {
        if (pItem.scnAcpnCmpltYn === 'Y') { // 현장접수완료여부(scnAcpnCmpltYn)
          pItem.prgPhseNm = '-' // 접수완료
          // 현장출금 여부
          if (pItem.frtmPrmCmpltYn !== 'Y') { // 초회보험료이체여부(frtmPrmCmpltYn)
            if (pItem.scnAcpnCmpltYmd === PSDateUtil.fn_CurrentDate()) { // 현장접수완료일자(scnAcpnCmpltYmd), 현장출금버튼은 현장접수완료일 당일만 표시
              if (pItem.frtmPrmPadMthCd === '1' || pItem.frtmPrmPadMthCd === '9' ) {  // 초회보험료납입방법코드(frtmPrmPadMthCd) 1:실시간이체, 9: 신용카드 (ASR240400333_24.05 장한나)
                if (pItem.frtmPrmTrsfYmd === '99999999') { // 초회보험료이체일자(frtmPrmTrsfYmd), 접수 결과 미 수신건 재접수 시 현장출금 예외처리
                  pItem.ofrMmgNm = '-'
                } else {
                  pItem.ofrMmgNm = '접수/출금'
                }
              } else {
                pItem.ofrMmgNm = '-'
              }
            } else {
              pItem.ofrMmgNm = '-'
            } // end else if
          } // end if
          // 현장접수/지점취소의 경우 버튼 표시
          
          if (PSCommUtil.fn_CurrntDayPlusDateChk(this.holyDayChkDateArry,1) <= pItem.joinDate) { // 워크데이 기준 D+1일 현장접수 버튼 표시
            if ( pItem.frtmPrmTrsfYmd !== '99999999') { // 초회보험료이체일자(frtmPrmTrsfYmd)
              pItem.ofrMmgNm = '접수/출금'
            } else pItem.ofrMmgNm = '-'
          }
          if (pItem.scnAcpnPsbYn === 'N') { // 접수불가건
            pItem.ofrMmgNm = '접수불가'
          }

        } else { // 현장접수완료여부(scnAcpnCmpltYn) : N

          if (pItem.elst5PhseCmpltYn === 'Y') { // 전자서명5단계완료여부(elst5PhseCmpltYn)

            pItem.prgPhseNm = '-' // 전자서명완료
            pItem.ofrMmgNm = '-'
            // 워크데이 기준 D+1일 현장접수 버튼 표시
            // 191213 : 오늘년도와 계약일자의 년도의 동일한지 검증 추가
            //        : 회계년도 마감으로 20191231에 전자서명완료건은 20200101,02에 접수할수 없음
            
            if (PSCommUtil.fn_CurrntDayPlusDateChk(this.holyDayChkDateArry,1) <= pItem.joinDate ) {
              //  && PSDateUtil.fn_CurrentDate().substr(0, 4) === pItem.joinDate.substr(0, 4)) { // 240101 보완 <- ASR221200180_회계년도관련로직제거 ( 이현수 / 221220_PJO)

              if ( pItem.frtmPrmTrsfYmd !== '99999999') { // 초회보험료이체일자(frtmPrmTrsfYmd)
                pItem.ofrMmgNm = '접수/출금'
              } else pItem.ofrMmgNm = '-'
              
            }

            if (pItem.scnAcpnPsbYn === 'N') { // 접수불가건
              pItem.ofrMmgNm = '접수불가'
            }

          } else if (pItem.elst2PhseCmpltYn === 'Y') {  // 전자서명2단계완료여부(elst2PhseCmpltYn)
            
            pItem.prgPhseNm = '재처리'
            pItem.ofrMmgNm = '-'
            // 워크데이 기준 D+1일 현장접수 버튼 표시
            // 191213 : 오늘년도와 계약일자의 년도의 동일한지 검증 추가
            //        : 회계년도 마감으로 20191231에 전자서명완료건은 20200101,02에 접수할수 없음
            if (PSCommUtil.fn_CurrntDayPlusDateChk(this.holyDayChkDateArry,1) <= pItem.joinDate ) {
            //  && PSDateUtil.fn_CurrentDate().substr(0, 4) === pItem.joinDate.substr(0, 4)) { // 240101 보완 <- ASR221200180_회계년도관련로직제거 ( 이현수 / 221220_PJO)

              if ( pItem.frtmPrmTrsfYmd !== '99999999') { // 초회보험료이체일자(frtmPrmTrsfYmd)
                pItem.ofrMmgNm = '접수/출금'
              } else {
                pItem.ofrMmgNm = '-'
              }  
            }

            if (pItem.scnAcpnPsbYn === 'N') { // 접수불가건
              pItem.ofrMmgNm = '접수불가'
            }

          } else {
            pItem.ofrMmgNm = '-'
            pItem.prgPhseNm = '-'
          }
        } // end if

      }
      rtnList.push(pItem)
    
    })//forEach 

    return rtnList
  },

  /*---------------------------------------------------------------------------------
  * 기타 Util 영역
  ---------------------------------------------------------------------------------*/


  /******************************************************************************
   * Function명  : fn_TableStat
   * 설명        : 데이터 없는 페이지 설정
   this.$t('ps')['EPSC012']
  *******************************************************************************/
  fn_TableStat (mesgTextCd,log =''){
    let val = this.$t('ps')[mesgTextCd]
    console.log(">>>fn_TableStat mesgText : "+val)
    this.mesgText = val

    this.rsltListCmplt = []

    let isSortReset = true
    this.$emit('on-service-result',this.idx,this.rsltListCmplt,isSortReset)
  },

  //--------------------------------------------------------------------------------
    _fn_End(){return}//코딩 종료 함수 마지막 메서드 함수 , 없음 주의
 },// methods

}//export default


</script>