/*
 * 업무구분 : 청약
 * 화면 명 : MSPPS241P
 * 화면 설명: 전자서명완료 재처리 팝업
              AS-IS  TSSPS211P 전환 
 */
<template>
  <ur-page-container title="전자서명재처리" :show-title="true" type="subpage" class="msp" @on-header-left-click="fn_HeaderBackBtnHandler">
    <ur-box-container direction="column" alignV="start" class="msp-ps-wrap">
      <ur-box-container alignV="start" componentid="" direction="column" class="ns-info-area">
        
        <ur-box-container alignV="start" componentid="" direction="column" class="info-box pb0" nowrap>

          <ur-box-container alignV="start" componentid="" direction="column" class="row-box bd-b-Ty2 bd-T-Ty1 mt30 pt16 pb11">
            <span class="w76px fs16rem fex-clumn fexJsCtTy4 h100"><span>상품명</span></span>
            <span class="flex-1 fs17rem fwb">{{rowPopupData.mnPrdtNm}}</span>
          </ur-box-container>
          <ur-box-container alignV="start" componentid="" direction="column" class="row-box bd-b-Ty2 pt16 pb11">
            <span class="w76px fs16rem fex-clumn fexJsCtTy4 h100"><span>계약자</span></span>
            <span class="flex-1 fs17rem fwb">{{rowPopupData.mnContrNm}}</span>
          </ur-box-container>
          <ur-box-container alignV="start" componentid="" direction="column" class="row-box bd-b-Ty1 pt16 pb11">
            <span class="w76px fs16rem fex-clumn fexJsCtTy4 h100"><span>청약일</span></span>
            <span class="flex-1 fs17rem fwb">{{elstCnclnYmd}}</span>
          </ur-box-container>

          <ur-box-container alignV="start" componentid="" direction="column" class="mt20">

            <ur-box-container v-for="level in levelCompletion" :key="level.step" alignV="start" componentid="" direction="column" class="row-box mb10">
              
              <div class="fexTy3 text-blue-box crTy-bk7" :class="{'bgcolor-1': !level.completion}">
                <div class="">{{level.label}}<span class="ml6 crTy-blue4 fwm">{{level.label2}}</span></div>
                
                <!-- 1단계 실패시 모든 버튼 비활성화 처리-->
                <div v-if="levelCompletion[0].completion === false" class="ns-check w100px">
                  <span class="ml4 crTy-blue3">-</span>
                </div>

                <div v-else-if="level.completion === true" class="ns-check w100px">
                  <mo-icon icon="msp-is-checked" class="fs26"></mo-icon><span class="ml4 crTy-blue3">완료</span>
                </div>

                <mo-button v-else color="normal" shape="border" size="small" class="blue bgcolor-2 mr38" @click="fn_LevelCompletion(level.step)">재처리</mo-button>

              </div>
              
            </ur-box-container>

          </ur-box-container>

        </ur-box-container>

        <ur-box-container alignV="start" componentid="" direction="column" class="ns-sch-area">
          <ur-box-container alignV="start" componentid="" direction="column" class="ns-btn-relative">
            <div class="relative-div">
              <mo-button componentid="" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_Close">확인</mo-button>
            </div>
          </ur-box-container>
        </ur-box-container>

      </ur-box-container>
    </ur-box-container>
  </ur-page-container>
</template>

<script>
/***********************************************************************************
* INCLUDE 영역
***********************************************************************************/
import Msg from '@/systems/webkit/msg/msg'
import { createScrollAnimateInstance } from '@/utils/scroll/scroll-animate'

import PSDateUtil from '@/ui/ps/comm/PSDateUtil'
import PSConstants from '@/config/constants/psConstants'
import PSServiceManager from '@/ui/ps/comm/PSServiceManager' 

export default {

  /***********************************************************************************
  * Vue 파일 속성 정의 영역
  * - name : 화면ID동일 영문만 기입 특수문자 한글 기입시 에러남
  * - screenId : 화면ID
  * - components : UI TAG 컴포넌트 정의
  ***********************************************************************************/
  name: 'MSPPS241P', 
  screenId: 'MSPPS241P', 
  isBackKeyHandle : true , // 팝업일때 디바이스 백키 직접 핸들 처리 여부 옵션

  components: {    
  },//componets,

  /***********************************************************************************
  * 화면 UI Property 정의
  * - 파리미터 받는 변수 props 정의
  ***********************************************************************************/
  props:{
    rowPopupData: Object
  },//props

  /***********************************************************************************
  * 팝업일때 팝업 활성 형태 속성 
  * - 풀 팝업 형태 일때 mode 값 설정
  ***********************************************************************************/
  
  modalProps: {
    mode: 'right' // 우->좌 open popup (defalut: 하->상)
  },
  

  /***********************************************************************************
  * 화면 전역 변수 선언 영역
  * - UI 바인딩 및 스크립트 화면 전역변수 정의
  ***********************************************************************************/
  data () {
    return {
      levelCompletion: [],
      eltrnSignPhseCd: '',
      eltrnSignCmpltPhseTrtVO: {},
      elstCnclnYmd: '', // 청약일
    }
  },//data

  /***********************************************************************************
  * 컴포넌트 BASE 라우터 네비게이션 가드 함수 정의영역
  * - 화면전환 Route Life Cycle 함수
  * - 주로 next(fals) 진행불가처리 ,next()진행 처리 스크립트 컨트롤용도
  *   사용시 함수 주석 풀고 스크립트 작성 할것  
  ***********************************************************************************/
  
  //router에 의해 화면진입시 호출 인스턴스 생성되기전이라 this 자원 접근안됨  
  //beforeRouteEnter (to, from, next) { 반드시  next(fals) OR next()  호출 },//beforeRouteEnter

  //router에 의해 화면을 벗어날때 호출 
  //beforeRouteLeave (to, from, next) { 반드시  next(fals) OR next()  호출 },//beforeRouteLeave


 /***********************************************************************************
  * Life Cycle 함수 정의 영역
  * - 각단계별 예약 함수에 맞게 스크립트 작성       
  ***********************************************************************************/

  // Life Cycle Step-1 인스턴스 초기화 후 데이터 관찰/이벤트/감시자 설정 전 호출 - 호출 부모 우선
  beforeCreate () {},//beforeCreate

  // Life Cycle Step-2 인스턴스가 작성된 후 동기적 호출 - this 접근 가능, el 접근 불가 - 호출 부모 우선  
  created () {
    // backButton event 등록
    //상단 isBackKeyHandle : true ,  팝업일때 디바이스 백키 직접 핸들 처리 여부 옵션 있어야 핸들링 가능
    window.vue.getWkEvtHandler().$on(Msg.DEVICE.BACK_KEY, this.fn_DevicBackBtnHandler)
    
  },//created

  // Life Cycle Step-3 마운트되기 바로 직전 전 호출 - this 접근 가능, el 접근 불가 - 호출 자식 우선
  beforeMount () {},//beforeMount

  // Life Cycle Step-4 마운트 된 후 호출 - 여기서 부터 모든 자원 접근 가능 - 호출 자식 우선
  // 이곳에서 초기설정 스크립트 작성
  mounted () {
    this.fn_init()
  },//mounted

  // Life Cycle Step-5 props 정의된 데이터가 변경되고 렌더링 되기 전 호출되는 수시호출함수
  beforeUpdate () {},//beforeUpdate

  // Life Cycle Step-6 props 데이터가 변경되고 렌더링 업데이트 후 호출되는 수시호출함수
  updated () {},//updated

  // Life Cycle Step-7 인스턴스가 Remove 되기 전 호출
  beforeDestroy () {},//beforeDestroy

  // Life Cycle Step-8 인스턴스가 Remove 된 후 호출
  destroyed () {
     // backButton event 해제      
    window.vue.getWkEvtHandler().$off(Msg.DEVICE.BACK_KEY, this.fn_DevicBackBtnHandler)

  },//destroyed

  
  /***********************************************************************************
   * watch 정의 영역
   * - data 영역에 바인딩 변수 정의한 값에 대한 값 변경시 지정 호출 함수 정의 
   *   (변수에 대한 값 변경시 반응형 콜백 처리)
   *   ex) data 영역 정의한 익명변수 userNm 
   *       userNm : function() { this.message + ' 님 안녕하세요!'}
  ***********************************************************************************/
  watch: {},//watch

 /***********************************************************************************
  * Computed 함수 정의 영역
  * - data 영역에 바인딩 변수 정의한 값에 대한 값 변경시 지정 호출 getter 함수 정의
  *   지정 함수는 반드시 리턴 하는 함수로 작성
  *   (변수에 대한 값 변경시 반응형 getter 함수 지정 처리)
  *   ex) data 영역 정의한 익명변수 userNm 
  *   userNm : function() { return this.userNm = this.userNm? this.userNm.trim() : '' } 
  ***********************************************************************************/
  computed: {},//computed

 /***********************************************************************************
  * 사용자 함수 정의 영역
  ***********************************************************************************/

  methods: {

  /*---------------------------------------------------------------------------------
  * UI 이벤트 핸들러 영역
  ---------------------------------------------------------------------------------*/
    /******************************************************************************
    * Function명  : fn_HeaderBackBtnHandler
    * 설명        : 헤더 이전버튼 Handler
    ******************************************************************************/
    fn_HeaderBackBtnHandler() {
     this.fn_DevicBackBtnHandler()
    },// fn_HeaderBackBtnHandler

    /******************************************************************************
    * Function명  : fn_DevicBackBtnHandler
    * 설명        : 디바이스 Back Button 핸들러
    ******************************************************************************/
    fn_DevicBackBtnHandler() {
      this.$emit('onPopupClose')
    },

    /******************************************************************************
    * Function명 : fn_init
    * 설명       : 초기화
    ******************************************************************************/

    fn_init () {
      this.eltrnSignPhseCd = ''
      this.eltrnSignCmpltPhseTrtVO = {}
      this.levelCompletion = [
        {label : '1단계'  ,label2 :'(전자문서보관)'   ,step: '1', completion: false},
        {label : '2단계'  ,label2 :'(신계약정보전송)' ,step: '2', completion: false},
        {label : '3단계'  ,label2 :'(주임메일)'      ,step: '3', completion: false},
        {label : '4단계'  ,label2 :'(서류이메일)'    ,step: '4', completion: false},
        {label : '5단계'  ,label2 :'(메세지전송)'    ,step: '5', completion: false}
      ]
      
      // 청약일 정제
      this.elstCnclnYmd = (this.rowPopupData.elstCnclnYmd && this.rowPopupData.elstCnclnYmd.trim().length > 0) ? PSDateUtil.fn_DateFormat(this.rowPopupData.elstCnclnYmd, 'yyyy-MM-dd') : '-'

      this.fn_ServiceData('S1')
    },
    /************************************************************************************************
     * Function명  : fn_LevelCompletion
     * 설명        : 재처리
     ************************************************************************************************/
    fn_LevelCompletion: function (step) {
      this.eltrnSignPhseCd = '0' + step
      this.fn_ServiceData('I2')
    },
    /************************************************************************************************
     * Function명  : fn_PhseCompletion
     * 설명        : 전자서명 단계 처리 체크 및 저장
     ************************************************************************************************/
    fn_PhseCompletion: function () {
      let phseCmpltYn = false
      // 모두 완료 처리 일때
      if (this.levelCompletion[0].completion && this.levelCompletion[1].completion &&
          this.levelCompletion[2].completion && this.levelCompletion[3].completion &&
          this.levelCompletion[4].completion && this.rowPopupData['elst5PhseCmpltYn'] !== 'Y') {

        this.rowPopupData['elst2PhseCmpltYn'] = 'Y' // 전자서명완료2단계완료여부
        this.rowPopupData['elst5PhseCmpltYn'] = 'Y'// 전자서명완료5단계완료여부
        phseCmpltYn = true

      } else if (this.levelCompletion[0].completion && this.levelCompletion[1].completion &&
          this.rowPopupData['elst2PhseCmpltYn'] !== 'Y') {

        this.rowPopupData['elst2PhseCmpltYn'] = 'Y' // 전자서명완료2단계완료여부
        this.rowPopupData['elst5PhseCmpltYn'] = 'N'// 전자서명완료5단계완료여부
        phseCmpltYn = true

      } // end else if

      if (phseCmpltYn) {
        this.fn_ServiceData('U4')
      } // end if
    },

    /************************************************************************************************
     * Function명  : fn_Close
     * 설명        : 팝업닫기
     ************************************************************************************************/
    fn_Close: function () {
      this.$emit('onPopupConfirm', this.levelCompletion)
    },

  /*---------------------------------------------------------------------------------
  * Data Service 핸들러 영역
  ---------------------------------------------------------------------------------*/
    /******************************************************************************
    * Function명  : fn_ServiceData
    * 설명        : 서비스 호출 함수 정의
    ******************************************************************************/
    fn_ServiceData: function (serviceName) {
      let pParams = {}
      
      switch (serviceName) {
        case 'S1': // 전자서명 단계별 처리 조회
          pParams = Object.assign({}, PSServiceManager.getParameter('txTSSPS13S5'))
          pParams.data = {}
          pParams.data.predMoblBusnScCd = 'SE' // 모바일업무구분코드
          pParams.data.predEltrnDoctScCd = '01' // 전자문서구분코드
          pParams.data.predEltrnDoctId = this.rowPopupData['vuchId'] // 전자문서ID
          break
        case 'I2': // 전자서명 단계 내역 저장
          pParams = Object.assign({}, PSServiceManager.getParameter('txTSSPS22I1'))
          pParams.data = this.eltrnSignCmpltPhseTrtVO
          pParams.data.eltrnSignPhseCd = this.eltrnSignPhseCd // 재처리 단계
          break
        case 'U3': // 전자서명진행상태코드수정
          pParams = Object.assign({}, PSServiceManager.getParameter('txTSSPS22U1'))
          pParams.data = {}
          pParams.data.moblBusnScCd = this.eltrnSignCmpltPhseTrtVO.predMoblBusnScCd
          pParams.data.eltrnDoctScCd = this.eltrnSignCmpltPhseTrtVO.predEltrnDoctScCd
          pParams.data.eltrnDoctId = this.eltrnSignCmpltPhseTrtVO.predEltrnDoctId
          break
        case 'U4': // 전자서명체결 수정
          pParams = Object.assign({}, PSServiceManager.getParameter('txTSSPS98U6'))
          pParams.data = {}
          pParams.data.vuchId = this.eltrnSignCmpltPhseTrtVO.predEltrnDoctId // 전자문서ID
          pParams.data.elst2PhseCmpltYn = this.rowPopupData['elst2PhseCmpltYn'] // 전자서명완료2단계완료여부
          pParams.data.elst5PhseCmpltYn = this.rowPopupData['elst5PhseCmpltYn'] // 전자서명완료5단계완료여부
          break
        default:
          break
      } // end switch
      pParams.srnId = this.$options.screenId // 대표 화면 명
      PSServiceManager.invoke(pParams, this.fn_ServiceResultHandler, serviceName, this.fn_ServiceFaultHandler)
    },

    /************************************************************************************************
     * Function명  : fn_ServiceDataResultHandler
     * 설명        : 서비스 호출 결과 함수 정의
     ************************************************************************************************/
    fn_ServiceResultHandler: function (event, serviceName) {
      let lv_data = event.data

      switch (serviceName) {
        case 'S1': // 전자서명 단계별 처리 조회
          this.eltrnSignCmpltPhseTrtVO = Object.assign({}, lv_data)
          let eltrnSignPhseCd01 = this.eltrnSignCmpltPhseTrtVO.eltrnSignPhseCd01.trim() // 전자문서보관
          let eltrnSignPhseCd02 = this.eltrnSignCmpltPhseTrtVO.eltrnSignPhseCd02.trim() // 신계약정보전송
          let eltrnSignPhseCd03 = this.eltrnSignCmpltPhseTrtVO.eltrnSignPhseCd03.trim() // 주임메일
          let eltrnSignPhseCd04 = this.eltrnSignCmpltPhseTrtVO.eltrnSignPhseCd04.trim() // 서류이메일
          let eltrnSignPhseCd05 = this.eltrnSignCmpltPhseTrtVO.eltrnSignPhseCd05.trim() // 메세지전송
          /** 하나라도 N 이라면 MOSE_PIPE70900P로 연결하는 버튼 활성화 시키기 : VO를 연결하기 */
          if (!eltrnSignPhseCd01 || eltrnSignPhseCd01 === 'N') {
            // 1단계의 경우는 worm에 전자서명을 저장하지 못 하였기 때문에 재처리가 불가능하다.
            // 그래서 재처리 버튼이 뜨지 않는 것이 맞다.
            // phase 1이 N일 경우 다른 단계 와 상관없이 무조건 재처리버튼 off
            // 2012.06.25 윤대일 책임, 박종훈 책임 확인 완료
            this.levelCompletion[0].completion = false
            this.levelCompletion[1].completion = false
            this.levelCompletion[2].completion = false
            this.levelCompletion[3].completion = false
            this.levelCompletion[4].completion = false
          } else {
            this.levelCompletion[0].completion = true // 전자문서보관
            if (!eltrnSignPhseCd02 || eltrnSignPhseCd02 === 'N') { // 신계약정보전송
              this.levelCompletion[1].completion = false
            } else {
              this.levelCompletion[1].completion = true
            } // end else if
            if (!eltrnSignPhseCd03 || eltrnSignPhseCd03 === 'N') { // 주임메일
              this.levelCompletion[2].completion = false
            } else {
              this.levelCompletion[2].completion = true
            } // end else if
            if (!eltrnSignPhseCd04 || eltrnSignPhseCd04 === 'N') { // 서류이메일
              this.levelCompletion[3].completion = false
            } else {
              this.levelCompletion[3].completion = true
            } // end else if
            if (!eltrnSignPhseCd05 || eltrnSignPhseCd05 === 'N') { // 메세지전송
              this.levelCompletion[4].completion = false
            } else {
              this.levelCompletion[4].completion = true
            } // end else if

            // 전자서명 리스트 저장값과 문서변환 정보 값이 상이 체크
            this.fn_PhseCompletion()

            if (this.eltrnSignCmpltPhseTrtVO) {
              this.eltrnSignCmpltPhseTrtVO.trtDtm = this.eltrnSignCmpltPhseTrtVO.trtDtm.substring(0, 10).replace('/', '-')
            } // end if
          }
          break
        case 'I2': // 전자서명 단계 내역 저장
          if (lv_data.wkRslt === 1) {
            if (this.eltrnSignPhseCd === '01') {
              this.eltrnSignCmpltPhseTrtVO.eltrnSignPhseCd01 = 'Y'
              this.levelCompletion[0].completion = true
            } else if (this.eltrnSignPhseCd === '02') {
              this.eltrnSignCmpltPhseTrtVO.eltrnSignPhseCd02 = 'Y'
              this.levelCompletion[1].completion = true
            } else if (this.eltrnSignPhseCd === '03') {
              this.eltrnSignCmpltPhseTrtVO.eltrnSignPhseCd03 = 'Y'
              this.levelCompletion[2].completion = true
            } else if (this.eltrnSignPhseCd === '04') {
              this.eltrnSignCmpltPhseTrtVO.eltrnSignPhseCd04 = 'Y'
              this.levelCompletion[3].completion = true
            } else if (this.eltrnSignPhseCd === '05') {
              this.eltrnSignCmpltPhseTrtVO.eltrnSignPhseCd05 = 'Y'
              this.levelCompletion[4].completion = true
            } // end else if

            if (this.eltrnSignCmpltPhseTrtVO.eltrnSignPhseCd01 !== 'N' && this.eltrnSignCmpltPhseTrtVO.eltrnSignPhseCd02 !== 'N' &&
              this.eltrnSignCmpltPhseTrtVO.eltrnSignPhseCd03 !== 'N' && this.eltrnSignCmpltPhseTrtVO.eltrnSignPhseCd04 !== 'N' &&
              this.eltrnSignCmpltPhseTrtVO.eltrnSignPhseCd05 !== 'N') {
              this.fn_ServiceData('U3')
            } else {
            } // end else if
          } else {
            this.getStore('confirm').dispatch('ADD', this.$t('ps')['EPSC012']) // '전자서명 재처리가 실패했습니다.')
          } // end else if
          break
        case 'U3': // 전자서명 완료여부 저장
          if (lv_data.wkRslt === 0) {
            // 전자서명 리스트 저장값과 문서변환 정보 값이 상이 체크
            this.fn_PhseCompletion()
          } else {
            this.getStore('confirm').dispatch('ADD', this.$t('ps')['EPSC012']) // '전자서명 재처리가 실패했습니다.')
          } // end else if
          break
        case 'U4': // 전자서명 완료여부 저장
          if (lv_data.trtRslt === 1) {
          } else {
            this.getStore('confirm').dispatch('ADD', this.$t('ps')['EPSC012']) // '전자서명 재처리가 실패했습니다.')
          } // end else if
          break
        default:
          break
      }
      
    },

    /************************************************************************************************
     * Function명  : fn_ServiceFaultHandler
     * 설명        : 에러 이벤트
     ************************************************************************************************/
    fn_ServiceFaultHandler: function (event, serviceName) {
      this.getStore('confirm').dispatch('ADD', event.msgDesc)
    },

  
  /*---------------------------------------------------------------------------------
  * 기타 Util 영역
  ---------------------------------------------------------------------------------*/


    _fn_End(){return}//코딩 종료 함수 마지막 메서드 함수 , 없음 주의
 },// methods

}//export default


</script>